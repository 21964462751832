import React, { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { typography, mq, colors, util } from 'src/styles'
import AnimateHeight from 'react-animate-height'
import PlusSign from 'src/assets/images/plus.svg'

const CollapseWrapper = styled.div`
  width: 100%;
  text-align: left;
  transition: margin 1s ease-in-out;
  &.open {
  	// margin-bottom: 50px;
  }
//   padding: 24px 0;
&:first-of-type {
	border-top: 1px solid ${ colors.black };
  }
`

const UnstyledButton = styled.button`
	display: block;
	appearance: none;
	line-height: unset;
  outline: none;
  width: 100%;
  border: none;
  background: inherit;
  font-size: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;
  border-radius: unset;
  box-shadow: unset;
  background: unset;
  color: inherit;
  height: unset;
  cursor: pointer;
  transition: none;
`

const CollapseHeader = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	&:hover {
		// color: ${ colors.green };
	}
	// background-color: chartreuse;
`

const DisplayName = styled.div`
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
  p {
	${ typography.h3 }
  	text-align: left;
  	margin: 24px 0;
  	// padding: 0.75em 0;
  	// ${ util.responsiveStyles('font-size', 20, 20, 18, 16) }
  	
  }
  transition: border 0.35s ease;
  border-bottom: 1px solid ${ colors.black };

  ${ ({ open }) => open === true ? `
	  border-bottom: 1px solid ${ colors.transparent };
	  background-color: ${ colors.highlight };
		p {
			
			// color: ${ colors.green };
		}
		${ mq.mediumAndBelow } {
			p {
			}
		}	
		svg {
			opacity: 0;
		}
	` : `
  	// border-bottom: 1px solid ${ colors.white };
	  svg {
		opacity: 1;
	  }
	`}	
	display: flex;
	justify-content: space-between;
	align-items: center;
	svg {
		display: block;
		width: 16px;
		height: 16px;
		transition: opacity 0.45s ease;
	}
`

const CollapsedContent = styled(AnimateHeight)`
	overflow: hidden;
	// ${ util.responsiveStyles('font-size', 20, 20, 18, 16) }
	p {
		// transition: margin 0.45s ease;
	}
	// transition: padding 0.25s ease;
	&.open {
		// padding: 0.75em 0 1em;
		border-bottom: 1px solid ${ colors.black };	
		background-color: ${ colors.highlight };
		// padding-bottom: 56px;
		p {
			margin-top: 10px;
			// color: ${ colors.green };

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		// border-bottom: none;
	}
`



const DetailsContainer = styled.div`
	padding-bottom: 56px;
`

const Collapse = ({ children, title, className }) => {
  const [ open, toggleOpen ] = useState(false)
  const handleClick = useCallback((e, open) => {
	e.stopPropagation();
	toggleOpen(!open);
  }, []);

  if (children) {
		return (
			<CollapseWrapper className={open ? className + ' open CollapseWrapper' : className} onClick={(e) => handleClick(e, open)}>
				{title && (
					<UnstyledButton open={open} className={open ? 'title open' : 'title'}>
						<CollapseHeader>
							<DisplayName hasChildren={true} open={open}>
								<p>{title}</p>
								<div><PlusSign /></div>
							</DisplayName>
						</CollapseHeader>
					</UnstyledButton>
				)}
				<CollapsedContent
					className={open ? 'content open' : 'content'}
					duration={400}
					delay={0}
					animateOpacity={true}
					height={!open ? 0 : 'auto'}
					open={open}
				>
					<DetailsContainer>
						{children}
					</DetailsContainer>
				</CollapsedContent>
			</CollapseWrapper>
		)
	} else {
		return (
			<CollapseWrapper className={className}>
				<CollapseHeader>
					<DisplayName>
						<div>{title}</div>
						
					</DisplayName>
				</CollapseHeader>
			</CollapseWrapper>
		)
	}
}

Collapse.defaultProps = {
	index: null,
	collapsed: true
}

export default Collapse
