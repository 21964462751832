import React from 'react'
import styled from '@emotion/styled'
import MailchimpSignup from 'src/components/MailchimpSignup'
import { colors, typography, animations, mq, util } from 'src/styles'

const Wrapper = styled.div`
`

const FormWrapper = styled.div`
  border: 1px solid ${ colors.black };
  padding: 24px;
`

const NewsletterTitle = styled.h6`
  ${ typography.bodySmall }
  font-family: ${ typography.secondaryFont };
  text-transform: uppercase;
`



const NewsletterSignup = ({ className }) => (
  <Wrapper>
    <NewsletterTitle>Sign Up for our newsletter!</NewsletterTitle>
    <FormWrapper className={className}>
      <MailchimpSignup 
        label="Newsletter Signup"
      />
    </FormWrapper>
  </Wrapper>
)

export default NewsletterSignup