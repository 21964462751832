import React from 'react'
import styled from '@emotion/styled'
import AnimateHeight from 'react-animate-height'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Collapse from 'src/components/Collapse'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import { themes } from 'src/styles/themes'
import { typography, mq, colors, animations, globals } from 'src/styles'


const Wrapper = styled.div`
	width: 100%;
	z-index: 1;
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }

	${ mq.mediumAndBelow } {
		width: 100%;
		&:not(:first-of-type) {
			margin-bottom: 110px;
		}

	}

	// &:not(:first-of-type) {
	// 	margin-top: 6.5em;
	// }
	h2 {
		margin: 1em 0;
	}

	p {
		a {
			border-bottom: 1px solid ${ colors.lightTextColor };
			&:hover {
				border-color: ${ colors.orange };
			}
			// position: relative;
			// border-bottom: none;
			// &:after {
			// 	content: '';
			// 	display: block;
			// 	position: absolute;
			// 	top: 85%;
			// 	left: 0;
			// 	width: 100%;
			// 	height: 1px;
			// 	background: ${ colors.lightTextColor };
			// }
			// &:hover {
			// 	&:after {
				
			// 	}
			// }
		}
	}	

	${ mq.mediumAndUp } {
		// ${ globals.verticalSpacing('padding-top', 0.5) }
		padding-right: 0.072vw;
		${ ({ count }) => count === 1 ? `
			justify-content: center;
		` : `` }

		${ ({ pageTitle, index }) => pageTitle === 'Home' ? `
		// flex-basis: 33.3333%;
		` : pageTitle === 'Archive' ? `

		` : pageTitle === 'About' ? `

		`: pageTitle === 'Press' ? `
		
		`: pageTitle === 'Co-Op' ? `
		
		`: pageTitle === 'Donate' ? `
		
		`: pageTitle === 'Partnerships' ? `

		`: pageTitle === 'Team' ? `

		`: pageTitle === '2018 Festival' ? `
		
		`: pageTitle === '2019 Festival' ? `

		`: pageTitle === 'What is Asia?' ? `
			// width: 66%;
			grid-column-start: 1;
			grid-column-end: 6;
		`: pageTitle === 'FAQ' ? `
			width: 66%;
		`: ``}
	}
`

const AccordionHeader = styled.h6`
	padding-left: 16px;
	padding-right: 16px;
`

const Text = styled.div`
	padding-left: 16px;
	padding-right: 16px;
	p {
		${ ({ textSize }) => typography[textSize] }
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
	}
`

const ActionWrapper = styled.div`
	padding: 16px 10px 0;
	display: flex;
	justify-content: center;
	vertical-align: middle;
`

const ButtonActions = styled.div`
	margin-top: 36px;
	text-align: ${ ({ alignment }) => alignment };
	margin-left: -10px;
	margin-right: -10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Accordion = ({ className, sectionTitle, items, width, alignment, theme = 'default', count, pageTitle }) => {
	// console.log('ACCORDION RENDER:::, items[0].content', items[0].content)
	if (items) {
		return (
			<Wrapper width={width} alignment={alignment} count={count} pageTitle={pageTitle}>
				{sectionTitle && (
					<AccordionHeader>{sectionTitle}</AccordionHeader>
				)}

			{items.map((item, index) => {
				return (
					<Collapse
						title={item.label}
						className={'accordion-' + sectionTitle}
						key={index}
					>
						<Text textSize='bodyMedium' alignment={alignment}><ContentfulRichText richText={item.content}/></Text>
						{/* <ContentfulRichText richText={item.content.json}/> */}
						{item.actions && (
						<ButtonActions buttons={item.actions} alignment={alignment}>
							{item.actions.map((action, index) => {
								if (action.__typename === 'ContentfulButton') {
									let actionTheme = 'default'
									if (action.theme === 'primary') {
										actionTheme = themes[theme].buttonTheme || 'default'
									} else if (action.theme === 'secondary') {
										actionTheme = themes[theme].buttonThemeSecondary || 'default'
									}
									return (
										<ActionWrapper key={'button-' + index}>
											<Button
												setTheme={actionTheme}
												to={action.to || action.linkToPage.slug}
												external={action.to}
												target={action.openInNewTab ? '_blank' : ''}
												title={action.label}
												name={action.label}
												shape="pill"
											>
												{action.label}
											</Button>
										</ActionWrapper>
									)
								} else {
									return (
										<ActionWrapper key={'button-' + index}>
											<TextLink
												to={action.to || action.linkToPage.slug}
												external={action.to}
												target={action.openInNewTab ? '_blank' : ''}
												title={action.label}
												name={action.label}
											>
												{action.label}
											</TextLink>
										</ActionWrapper>
									)
								}
							})}
						</ButtonActions>
					)}
					</Collapse>
				)
			})}

			</Wrapper>
		)
	}
}

export default Accordion
