import React from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import { Logomark } from 'src/components/Logo'
import Section from 'src/components/Section'
import ThemeSelector from 'src/components/ThemeSelector'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import MailchimpSignup from 'src/components/MailchimpSignup'
import NewsletterSignup from 'src/components/NewsletterSignup'

import { mq, util, typography, colors } from 'src/styles'

const Wrapper = styled(ThemeSelector)`
	position: relative;
	z-index: 2;
	color: ${ colors.black };
`

const FooterLogo = styled(Logomark)`
	width: 30px;
	${ mq.mediumAndBelow } {
		width: 24px;
	}
`

const FooterTop = styled(Section)`
${ util.responsiveStyles('padding-bottom', 50, 40, 30, 26) }
`

const FooterBottom = styled(ThemeSelector)`
	position: relative;
	// ${ util.responsiveStyles('padding-top', 50, 40, 30, 26) }
	${ util.responsiveStyles('padding-bottom', 50, 40, 30, 26) }
	
`

const ListHeader = styled.p`
	${ typography.h6 }
	margin: 0 0 .5em;
	display: inline;
`

const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	${ mq.mediumAndBelow } {
		&:last-of-type {
			text-align: right;
		}
	}
`

const NewsletterWrapper = styled.div`


`



const Copyright = styled.div`
	color: ${ colors.black };
	p {
		${ typography.bodySmall }
		text-align: left;
		margin: 0;
		max-width: none;
		.mobile-hide {
			${ mq.mediumAndBelow } {
				display: none;
			}
		}
	}
`

const SiteCredit = styled.div`
	text-align: right;
	p {
		margin: 0;
		max-width: none;
		a {
			opacity: 0.6;
			&:hover {
				opacity: 1;
			}
		}
	}
`
const FooterLink = styled(Link)`
	${ typography.bodySmall }
	font-family: ${ typography.secondaryFont };
	text-transform: uppercase;
	${ mq.mediumAndBelow } {
		margin-top: 12px;
	}
`

const Footer = ({ title }) => {
	return (
		<Wrapper setTheme="default">
			<FooterTop>
				<Grid
					small="1 [4] [4] [4] 1"
					medium="1 [2] [2] [2] [5] 1"
					large="1 [2] [2] [2] [5] 1"
					vAlign="top"
				>	
					<ListWrapper>
						<FooterLink to={'/about'}>About</FooterLink>
						<FooterLink to={'/archive'}>Archive</FooterLink>
						<FooterLink to={'/co-op'}>Co-Op</FooterLink>
						<FooterLink to={'/contact'}>Contact</FooterLink>
					</ListWrapper>
					<ListWrapper>
						<FooterLink to={'/donate'}>Donate</FooterLink>
						<FooterLink to={'/faq'}>FAQ</FooterLink>
						<FooterLink to={'/partnerships'}>Partnerships</FooterLink>
						<FooterLink to={'/press'}>Press</FooterLink>
					</ListWrapper>
					<ListWrapper>
						<FooterLink to={'/radio'}>Radio</FooterLink>
						<FooterLink to="https://recipes.happyfamilymkt.com/" target="_blank" external>Recipes</FooterLink>
						<FooterLink to="https://www.instagram.com/happyfamilymkt/" target="_blank" external>Instagram</FooterLink>
						<FooterLink to="https://twitter.com/happyfamilymkt" target="_blank" external>Twitter</FooterLink>
					</ListWrapper>
					<ResponsiveComponent
                      small={<span></span>
                      }
                      medium={
						<NewsletterWrapper>
							<MailchimpSignup label="Newsletter Signup"/>
						</NewsletterWrapper>
                      }
                    />
				</Grid>
			</FooterTop>
			<FooterBottom setTheme="textColor">
				<Grid
					small="1 [12] 1"
					medium="1 [12] 1"
					large="1 [12] 1"
					vAlign="center"
				>
					<Copyright>
						<p className="sm">© <span>{title}</span> {new Date().getFullYear()}</p>
					</Copyright>
				</Grid>
			</FooterBottom>
		</Wrapper>
	)
}

export default Footer
