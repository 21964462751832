import React, { Fragment, Component, useState } from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import MaterialIcon from 'src/components/MaterialIcon'
import Grid from 'src/components/Grid'

import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { colors, typography, animations, mq, util } from 'src/styles'

const McUrl = '//happyfamilymkt.us18.list-manage.com/subscribe/post?u=7d1e5263c6cf3ea8f134c7b13&amp;id=53d9f328fd'

const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	// min-height: 25vh;
	${ mq.mediumAndBelow } {
		padding: 15px 0;
	}
`

const FormHeader = styled.div`
  ${ typography.bodySmall }
  font-family: ${ typography.secondaryFont };
  text-transform: uppercase;
  ${ mq.mediumAndBelow } {
	margin-top: 40px;
	}
	margin-bottom: 10px;
	${ ({ status}) => status === "success" ? `
      color: ${ colors.orange };
    ` : ``}
    
`

const EmailWrap = styled(Grid)`
	margin-top: 15px;
`

const NameWrap = styled.div`
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
`

const MCInput = styled(Input)`
	border-radius 0;
	input.input {
		padding-right: 0;
		
	}
	${ typography.bodyMedium };
	font-family: ${ typography.primaryFont };
	font-size: 12px;
	&:first-of-type {
		margin-right: 8px;
	}
`

const SubmitClick = styled.div`
	flex-grow: 1;
	${ mq.mediumAndBelow } {
		margin-top: 20px;
		p {
			text-align: center;
		}
	}
	&:hover {
		cursor: pointer;
		p {
			color: ${ colors.orange };
			font-weight: 400;
		}
	}
	p {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 12px;
		font-family: ${ typography.secondaryFont };
		text-transform: uppercase;
		line-height: 1.55em;
	}
`

// a basic form
const CustomForm = ({ status, message, onValidated, className, placeholder, label, size }) => {
	const [formData, setFormData] = useState({})
	const [formType, setFormType] = useState('general')

	const submit = () => {
		onValidated(formData)
	}
	const renderIcon = buttonStatus => {
		let icon = <MaterialIcon>arrow_forward</MaterialIcon>
		if (buttonStatus === 'sending') {
			// icon = <Loader />
			icon = <MaterialIcon>more</MaterialIcon>
		} else if (buttonStatus === 'success') {
			icon = <MaterialIcon>check</MaterialIcon>
		} else if (buttonStatus === 'error') {
			icon = <MaterialIcon>close</MaterialIcon>
		} else {
			icon = <MaterialIcon>arrow_forward</MaterialIcon>
		}
		return icon
	}

	const handleChange = event => {
		const { name, value } = event.target
		setFormData(prevState => ({
		  ...prevState,
		  [name]: value
		}))
		}

	return (
		<Fragment>
			{ !status && (
				<FormHeader>Sign Up for our newsletter</FormHeader>
			) }
			{status === "sending" && (
			<FormHeader status={status} className="mc__alert mc__alert--sending">
				sending...
			</FormHeader>
			)}
			{status === "error" && (
			<FormHeader 
				status={status}
				className="mc__alert mc__alert--error"
				dangerouslySetInnerHTML={{ __html: message }}
			/>
			)}
			{status === "success" && (
			<FormHeader
				status={status}
				className="mc__alert mc__alert--success"
				dangerouslySetInnerHTML={{ __html: message }}
			/>
			)}
			<FormWrapper className={className}>
				<Grid small='[1] [1]' medium='[1] [1]' rowGap='14px' colGap='14px'>
					<MCInput
						size={'small'}
						type="text"
						placeholder='First name'
						label=''
						name="FNAME"
						onChange={handleChange}
					/>
					<MCInput
						size={'small'}
						type="text"
						placeholder='Last name'
						label=''
						name="LNAME"
						onChange={handleChange}
					/>
				</Grid>
				<EmailWrap small='[1]' medium='[3] [1]' rowGap='14px' colGap='14px' vAlign="bottom">
					<MCInput
						size={'small'}
						type="email"
						placeholder='Email address'
						label=''
						name="EMAIL"
						onChange={handleChange}
					/>
					<SubmitClick onClick={submit}>
						<p>( Subscribe )</p>
					</SubmitClick>
					
				</EmailWrap>
			</FormWrapper>
		</Fragment>
	)
}

class MailchimpSignup extends Component {
	render () {
		return (
			<MailchimpSubscribe
				url={McUrl}
				render={({ subscribe, status, message }) => (
					<CustomForm
						status={status}
						message={message}
						onValidated={formData => subscribe(formData)}
						className={this.props.className}
						placeholder={this.props.placeholder}
						label={this.props.label}
						size={this.props.size || 'small'}
					/>
				)}
			/>
		)
	}
}

export default MailchimpSignup

// <div id="mc_embed_signup">
//       <form action="https://happyfamilymkt.us18.list-manage.com/subscribe/post?u=7d1e5263c6cf3ea8f134c7b13&amp;id=53d9f328fd" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate="">
//           <div id="mc_embed_signup_scroll">
//       <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
//       <div class="mc-field-group">
//         <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
//       </label>
//         <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" />
//       </div>
//       <div class="mc-field-group">
//         <label for="mce-FNAME">First Name </label>
//         <input type="text" value="" name="FNAME" class="" id="mce-FNAME"/>
//       </div>
//       <div class="mc-field-group">
//         <label for="mce-LNAME">Last Name </label>
//         <input type="text" value="" name="LNAME" class="" id="mce-LNAME"/>
//       </div>
//         <div id="mce-responses" class="clear">
//           <div class="response" id="mce-error-response"></div>
//           <div class="response" id="mce-success-response"></div>
//         </div>   
//           <div aria-hidden="true"><input type="text" name="b_7d1e5263c6cf3ea8f134c7b13_53d9f328fd" tabindex="-1" value=""/></div>
//           <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"/></div>
//           </div>
//       </form>
//     </div>