import React from 'react'
import styled from '@emotion/styled'
import TextLockup from 'src/components/TextLockup'
import Button from 'src/components/Button'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { GatsbyImage } from 'gatsby-plugin-image'
import { colors, typography, mq } from 'src/styles'

const Wrapper = styled.div`
  padding: 0 15vw;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 155%;
    // max-width: 50em;
    
  }
  ul {
    padding-left: 20px;
    li {
      p {
        margin: 8px 0;
      }
    }
  }
  ${ mq.smallAndBelow } {
    padding: 0 10px;
  }
`

const Title = styled.h2`
  margin: 50px 0 12px;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  padding: 0 16px;
  ${ mq.smallAndBelow } {
    font-size: 34px;
  }
`

const ParticipantsWrap = styled.div`
`

const Participant = styled.div`
  margin-top: 100px;
  ${ mq.smallAndBelow } {
    margin-top: 50px;
  }
  border-bottom: 1px solid ${ colors.lightTextColor };
`

const ParticipantInfo = styled.div``

const ParticipantHighlightWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 14px;
`

const ParticipantHighlight = styled.div`
  background: ${ colors.lightGrey };
  max-width: 50rem;
  margin: 5px 0 75px;
`

const ParticipantPhotoWrap = styled.div`
  position: relative;
`
const MainImageWrap = styled.div``

const StickerWrap = styled.div`
  width: 200px;
  height: auto;
  position: absolute;
  bottom: -75px;
  right: -40px;
  transform: rotate(25deg);
  ${ mq.smallAndBelow } {
    width: 100px;
    bottom: -45px;
    right: 10px;
  }
`

const ParticipantLinksWrap = styled.div`
  // background: white;
  padding: 0 14px;
  margin-bottom: 25px;
  ${ mq.smallAndBelow } {
    padding: 0 10px;
  }
`

const ParticipantLink = styled.div`
  ${ mq.largeAndUp } {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
  }
  border: 1px solid black;
  margin-bottom: 10px;
  background: ${ colors.lightGrey };
  ${ mq.mediumAndBelow } {
    padding-bottom: 20px;
  }
`

const ParticipantLinkDescriptionWrap = styled.div`
  ${ mq.largeAndBelow } {
    max-width: 30rem;
  }
  ${ mq.largerAndUp } {
    max-width: 41rem;
  }
`

const ParticipantLinkButtonWrap = styled.div`
  ${ mq.mediumAndBelow } {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const GiftGuide = ({ className, title, coverImage, description, participants, postScript }) => {
  
  return (
    <Wrapper className={className}>
      <GatsbyImage
        image={coverImage.gatsbyImageData}
        loading={'lazy'}
        alt={coverImage.file.fileName}
      />
      <Title>{title}</Title>
      <TextLockup
        text={description}
        stroke={false}
        tabGroup={false}
      />

      <ParticipantsWrap>
        {participants.map((item, index) => {
          return (
            <Participant key={index}>
              <ParticipantPhotoWrap>
                <MainImageWrap>
                  <GatsbyImage
                    image={item.productPhoto.gatsbyImageData}
                    loading={'lazy'}
                    alt={coverImage.file.fileName}
                  />
                </MainImageWrap>
                <StickerWrap>
                  <GatsbyImage
                    image={item.productSticker.gatsbyImageData}
                    loading={'lazy'}
                    alt={coverImage.file.fileName}
                  />
                </StickerWrap>
              </ParticipantPhotoWrap>
              <Title>{item.creatorName} - {item.productName}</Title>
              <ParticipantInfo>
                {item.creatorDescription && (
                  <TextLockup
                    text={item.creatorDescription}
                    stroke={false}
                    tabGroup={false}
                  />
                )}
                
                {item.productDescription && (
                  <TextLockup
                    text={item.productDescription}
                    stroke={false}
                    tabGroup={false}
                  />
                )}
              </ParticipantInfo>

              <ParticipantLinksWrap>
                {item.linkList.map((linkPair, index) => {
                  return (
                    <ParticipantLink key={index}>
                      <ParticipantLinkDescriptionWrap>
                        <TextLockup
                          text={linkPair.linkDescription}
                          stroke={false}
                          tabGroup={false}
                          theme='lightGrey'
                          // textSize={'bodyLarge'}
                        />
                      </ParticipantLinkDescriptionWrap>
                      <ParticipantLinkButtonWrap>
                        <Button
                          setTheme={'mainColor'}
                          to={linkPair.ctaLinkButton.to || linkPair.ctaLinkButton.linkToPage.slug}
                          external={linkPair.ctaLinkButton.to}
                          target={linkPair.ctaLinkButton.openInNewTab ? '_blank' : ''}
                          title={linkPair.ctaLinkButton.label}
                          name={linkPair.ctaLinkButton.label}
                          shape="pill"
                        >
                          {linkPair.ctaLinkButton.label}
                        </Button>
                      </ParticipantLinkButtonWrap>
                    </ParticipantLink>
                  )
                })}
              </ParticipantLinksWrap>

              {item.highlightCopy && (
                <ParticipantHighlightWrap>
                  <ParticipantHighlight>
                    <TextLockup
                      text={item.highlightCopy}
                      stroke={false}
                      tabGroup={false}
                      theme='transparent'
                      />
                  </ParticipantHighlight>
                </ParticipantHighlightWrap>
              )}

            </Participant>
          )
        })}

      </ParticipantsWrap>
      {postScript && (
        <TextLockup
          text={postScript}
          stroke={false}
          tabGroup={false}
        />
      )}
    </Wrapper>
  )
}

export default GiftGuide
