import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'

import { colors, typography, animations, mq } from 'src/styles'

// const Wrapper = styled.div``
const QuotesWrapper = styled(Section)`
  ${ mq.mediumAndUp } {
    ${ ({ isFirstSection }) => isFirstSection === true ? `
      flex-basis: 66.6666%;
    ` : `
      flex-basis: 33.3333%;
      ${ globals.verticalSpacing('padding-top', 0.5) }
      // ${ ({ index }) => index % 2 ==! 0 ? `
      // flex-basis: 33.3333%;
      // ` : `
      // ` }  
    ` }

    ${ ({ pageTitle, index }) => pageTitle === 'Home' ? `
  
    ` : pageTitle === 'Archive' ? `
      order: 2;
    ` : pageTitle === 'About' ? `

    `: pageTitle === 'Press' ? `
      order: 2;
    `: pageTitle === 'Co-Op' ? `
    
    `: pageTitle === 'Donate' ? `
    
    `: pageTitle === 'Partnerships' ? `

    `: pageTitle === 'Team' ? `

    `: pageTitle === '2018 Festival' ? `
    
    `: pageTitle === '2019 Festival' ? `
    
    ` :``}
  }
  
`

const QuoteWrap = styled.div`
  // background: aliceblue;
  padding: 0 16px;
  margin-bottom: 56px;
  &:last-of-type {
    margin-bottom: 36px;
  }
  &:first-of-type {
    padding-top: 36px;
  }
`

const Quote = styled.div`
  padding-left: 16px;
  p {
    ${ typography.quoteLg }
  }
`


const Author = styled.h5`
  padding-left: 16px;
  font-weight: normal;
`

const PressLink = styled(Link)`
  text-decoration: underline;
`

const PressQuote = ({ className, quotes, isFirstSection, index, pageTitle }) => (
  <QuotesWrapper className={className} isFirstSection={isFirstSection} index={index} pageTitle={pageTitle}>
    <Grid
      small="[14]"
      medium="[14]"
      large="[14]"
      larger="[14]"
    >
      {quotes.map((quote, index) => {
          return (
            <QuoteWrap>
              <PressLink to={quote.linkUrl} target="_blank" external><Author>{quote.author}</Author></PressLink>
              
              <Quote>
                <ContentfulRichText richText={quote.quote}/>
              </Quote>
              
            </QuoteWrap>
          )
        })}
    </Grid>
  </QuotesWrapper>
)

export default PressQuote
