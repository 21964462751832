import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import * as PropTypes from 'prop-types'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import { colors, typography, animations, mq, util } from 'src/styles'

const propTypes = {
	data: PropTypes.object.isRequired,
}

const SectionsWrap = styled.div`
	${ mq.mediumAndUp } {
		${ ({ count, pageTitle }) => count === 1 ? `
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			flex-grow: 1;
			justify-content: center;
		` : `
		${ pageTitle === 'Radio' ? `

		` : pageTitle === 'Archive' ? `
			// background-color: chartreuse;
			padding: 0 0 56px;
			display: grid;
			grid-template-columns: 2fr 4fr;
			// grid-auto-rows: minmax(400px, auto);
			// gap: 10px;
			// row-gap: 56px;

		` : pageTitle === 'What is Asia?' ? `  
			// display: grid;
			// grid-template-columns: 1fr;
		
		` : `
			padding: 0 1.5vw 56px;
			display: grid;
			grid-template-columns: 2fr 4fr;
			grid-auto-rows: minmax(400px, auto);
			gap: 10px;
			row-gap: 56px;
		`}
		// grid-auto-rows: 400px 800px;
		// grid-auto-flow: column;
		// grid-auto-columns: 300px 100px;

		// grid-template-areas: 'left right';
		// grid-template-columns: 1fr 2fr;
		// grid-template-columns: [left] 33% [right] 66%;
		// grid-template-areas: 'topLeft topRight'
        //                  'midLeft midRight'
		// 				 'botLeft botRight;
		// grid-template-rows: [row1] 50% [row2] 25% [row3] 25%;
		// grid-auto-rows: minmax(300px, auto)
		// grid-gap: 20px;
		` }
		padding-bottom: 56px;
	}

`

class PageTemplate extends React.Component {
	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges[0].node
		const page = this.props.data.allContentfulPage.edges[0].node
		const { sections } = page
		const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
		const seo = page.seo
		const count = sections.length
		console.log('count::', count)
		console.log('page-title::', page.title)
		
		return (
			<Fragment>
				<SEO
					title={page.title}
					description={seo.description && seo.description.description}
					siteSettings={site}
					keywords={seo.keywords}
					shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
				/>
				<Header
					hasAtf={hasAtf}
					bannerText={site.bannerText}
					bannerColor={site.bannerColor}
					navigation={site.navigation}
					location={page.slug}
					title={site.title}
					pageTitle={page.title}
				/>
				<SectionsWrap count={count} pageTitle={page.title}>
				{sections && sections.map((section, index) => {
					const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].__typename
					const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
					const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].__typename
					const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
					const lastSection = sections.length === index + 1
					
					return (
						<ComponentRenderer
							prevTheme={prevFullWidth ? false : prevTheme}
							nextTheme={nextFullWidth ? false : nextTheme}
							isFirstSection={index === 0}
							isLastSection={lastSection}
							key={section.id + index}
							item={section}
							index={index}
							count={count}
							pageTitle={page.title}
						/>
					)
				})}
				</SectionsWrap>
				<Footer {...site} />
			</Fragment>
		)
	}
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					title
					slug
					sections {
						...Columns
						...FiftyFifty
						...TextSection
						...WideMedia
						...Accordion
						...TabGroup
						...CroppedImage
						...LinkList
						...Carousel
						...QuoteGroup
						...RadioList
						...LogoGroup
						...VideoEmbed
						...FilmEntry
						...GiftGuide
					}
					seo {
						description {
							description
						}
						keywords
						shareImage {
							file {
								url
							}
						}
					}
				}
			}
    }
	}
`

export default PageTemplate
