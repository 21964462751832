import React from 'react'
import styled from '@emotion/styled'
import TextLockup from 'src/components/TextLockup'
import ContentfulRichText from 'src/components/ContentfulRichText'
import CroppedImage from 'src/components/CroppedImage'
import { typography, util, animations, mq } from 'src/styles'

const Wrapper = styled.div`

  ${ mq.smallAndBelow } {
    padding: 0 10px;
  }

`
const TopRow = styled.div`
  ${ mq.mediumAndUp } {
    display: grid;
    grid-template-columns: 4fr 3fr;
    padding: 50px 20px 75px;
  }
  ${ mq.smallAndBelow } {
    margin-bottom: 50px; 
  }
`

const MiddleRow = styled.div`
  ${ mq.mediumAndUp } {
    display: grid;
    grid-template-columns: 4fr 3fr;
    padding: 0 20px;
  }
`

const BottomRow = styled.div`
  ${ mq.mediumAndUp } {
    display: grid;
    grid-template-columns: 4fr 3fr;
    padding: 0 20px;
  }
  ${ mq.smallAndBelow } {
    margin-top: 50px; 
  }
`

const TitleWrap = styled.div`
  ${ mq.smallAndBelow } {
    margin-bottom: 25px; 
  }
`

const Title = styled.h1`
  ${ typography.h2 }
`
const Author = styled.p`
  margin: 0.75em 0 1em 5px;
  ${ typography.bodyLarge }
  font-family: ${ typography.secondaryFont };
  ${ mq.smallAndBelow } {
    margin: 0.5em 0 0.75em 5px;
    font-size: 24px;
    font-family: ${ typography.secondaryFont };
  }
`

const FilmInfoWrap = styled.div`
  padding-left: 7px;
  p {
    ${ typography.bodyMedium }
    // font-family: ${ typography.secondaryFont };
    ${ mq.smallAndBelow } {
      ${  typography.bodySmall }
    }
  }
`
const SummaryWrap = styled.div`

  p {
    // font-family: ${ typography.secondaryFont };
  }
`
const StatementWrap = styled.div`

`
const HeadshotWrap = styled.div`
  padding: 0 15px 0 40px;
`
const BioWrap = styled.div``


const FilmEntry = ({ className, title, author, runTime, filmYear, filmInfo, summary, statement, headshot, authorBio, image, videoType, videoId }) => {
  
  return (
  <Wrapper className={className}>
    <TopRow>
      <TitleWrap>
        <Title>{title}</Title>
        <Author>{author}</Author>
        <FilmInfoWrap>
          <ContentfulRichText richText={filmInfo}/>
        </FilmInfoWrap> 
      </TitleWrap>
    
      <SummaryWrap>
        <TextLockup
          text={summary}
          stroke={true}
          tabGroup={false}
        />
      </SummaryWrap>
    </TopRow>
    <MiddleRow>
      <StatementWrap>
        <TextLockup
          text={statement}
          stroke={true}
          tabGroup={false}
        />
      </StatementWrap>
    </MiddleRow>

    <BottomRow>
    {headshot && (
      <HeadshotWrap>
        <CroppedImage
          image={headshot.image}
          cropStyle={headshot.cropStyle}
          shapeColor={headshot.shapeColor}
        />
      </HeadshotWrap>
    )}
      <BioWrap>
        <TextLockup
          text={authorBio}
          stroke={true}
          tabGroup={false}
        />
      </BioWrap>
    </BottomRow>

  </Wrapper>

)}

export default FilmEntry
