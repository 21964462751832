import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import TabSection from 'src/components/TabSection'

import { colors, typography, animations, mq, globals } from 'src/styles'


const Wrapper = styled(Section)`
  display: block;
  ${ ({ alignment }) => alignment !== 'right' ? `
    text-align: ${ alignment };
  ` : `` }
  h1, h2, h3, h4, h5, h6, p {
    ${ ({ alignment }) => alignment === 'center' ? `
      margin-left: auto;
      margin-right: auto;
    ` : `` }
  }
  h1, h2, h3 {
    max-width: 20em;
  }
  h4, h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 36em;
  }
  ${ mq.mediumAndUp } {
    
    ${ ({ count, isFirstSection}) => count === 1 ? `
      flex-basis: 66.6666%;
      align-self: center;
    ` : `
        ${ isFirstSection ? `
        flex-basis: 33.3333%;
        ` : `
      `}
    ` }
    ${ ({ pageTitle, index }) => pageTitle === 'Home' ? `
      // flex-basis: 33.3333%;
    ` : pageTitle === 'Archive' ? `
      order: 2;
    ` : pageTitle === 'About' ? `

    `: pageTitle === 'Press' ? `
    
    `: pageTitle === 'Co-Op' ? `
        order: 4;
    `: pageTitle === 'Donate' ? `
    ${index === 1 ? `
      order: 2;
    ` : `
      order: 5;
    `}
    `: pageTitle === 'Partnerships' ? `

    `: pageTitle === 'Team' ? `

    `: pageTitle === '2018 Festival' ? `
    
    `: pageTitle === '2019 Festival' ? `
    
    ` :``}
  }

`

const GroupWrapper = styled.div``

const GroupTitle = styled.h6`
`

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.div`
  flex: 1;
  min-height: 2.5em;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    ${ typography.tabHeader }
    text-transform: uppercase;
    line-height: 1.2em;
    margin: 0;
    padding-left: 16px;
  }
  &:hover {
    cursor: pointer;
  }
  border-top: 1px solid ${colors.black};
  border-right: 1px solid ${colors.black};
  border-bottom: 1px solid ${colors.black};
  border-left: 1px solid ${colors.black};
  padding: 8px;
  transition: border 0.1s linear, background ${ animations.mediumSpeed } linear;
  &:first-of-type {
    border-right: 1px solid ${colors.transparent };
  }
  &:last-of-type {
    border-left: none;
  }

  ${mq.mediumAndBelow} {
    min-height: 3em;
    p {
      padding-left: 5px;
    }
  }

  ${ ({ active }) => active === true ? `
    // border-bottom: none;
    border-bottom: 1px solid ${ colors.highlight };
    background-color: ${ colors.highlight };
  ` : `
      

  ` }
`

const TabGroup = ({ 
  className,
  nextTheme,
  prevTheme,
  theme,
  title,
  colorTheme,
  sections,
  isFirstSection,
  count,
  index,
  pageTitle
}) => {
  if (!sections) {
    return false
  }
  const [activeTab, setActiveTab] = useState(1)
  const vary = ( count % 3 + index ) % 2
  // console.log('Tabgroup Vary', vary) 
  return (
    <Wrapper className={className} isFirstSection={isFirstSection} count={count} vary={vary} index={index} pageTitle={pageTitle}>

      <Grid
        small="1 [12] 1"
        medium="1 [12] 1"
        large="1 [12] 1"
        larger="1 [12] 1"
        vAlign="top"
      >
        <GroupWrapper>
          {title && (
            <GroupTitle>{title}</GroupTitle>
          )}
          <Tabs>
            {sections.map((section, index) => {
              return (
                <Title key={index} onClick={() => setActiveTab(index)} active={activeTab === index ? true : false} >
                  <p>{section.title}</p>
                </Title>
              )
            })}
          </Tabs>
          {sections.map((section, index) => {
            if (index === activeTab) {
              return (
                <TabSection 
                  title={section.title}
                  text={section.text}
                  colorTheme={colorTheme}
                  active={true}
                  actions={section.actions}
                  key={index} 
                />
              )
            }
            })}
        </GroupWrapper>
        </Grid>
    </Wrapper>
  )
}

export default TabGroup
