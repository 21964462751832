import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import { typography, mq, colors, animations, globals } from 'src/styles'

const Wrapper = styled(Section)`
  ${ ({ alignment }) => alignment !== 'right' ? `
    text-align: ${ alignment };
  ` : `` }
  h1, h2, h3, h4, h5, h6, p {
    ${ ({ alignment }) => alignment === 'center' ? `
      margin-left: auto;
      margin-right: auto;
    ` : `` }
  }
  h1, h2, h3 {
    max-width: 20em;
  }
  h4, h4 {
    max-width: 26em;
  }
  h5 {
    max-width: 36em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 36em;
  }
  // ${ ({ isFirstSection }) => isFirstSection === true ? `
  //     flex-basis: 33.3333%;
  //   ` : `` }

  ${ mq.mediumAndUp } {
    ${ ({ count, isFirstSection, vary, index }) => count === 1 ? `
      flex-basis: 66.6666%;
      align-self: center;
    ` : `
        ${ isFirstSection ? `
        flex-basis: 33.3333%;
      
      ` : `
        ${ vary === 1 ? `
          // grid-area: right;
        ` : `
        // grid-area: left;
        `}
        
    
      `}
    ` }

    ${ ({ pageTitle, index }) => pageTitle === 'Home' ? `
      // flex-basis: 33.3333%;
    ` : pageTitle === 'Archive' ? `

    ` : pageTitle === 'About' ? `

    `: pageTitle === 'Press' ? `
      &:last-of-type {
        grid-column-start: 1;
      }
    
    `: pageTitle === 'Co-Op' ? `

    `: pageTitle === 'Donate' ? `
    ${index === 0 ? `
      order: 1;
    `:`
      order: 4;
    `}
    `: pageTitle === 'Partnerships' ? `

    `: pageTitle === 'Team' ? `

    `: pageTitle === 'Festival' ? `
      
      &:first-of-type {
        background-color: chartreuse;
        grid-column-start: 1;
        grid-column-end: 6;
        margin-top: 75px;
        margin-bottom: 25px;
      }
      &:not(:first-of-type) {
        grid-column-start: 1;
        grid-column-end: 6;
        margin-top: 250px;
        margin-bottom: -25px;
      }
      p { 
        font-size: 36px !important;
      }

    `: pageTitle === '2018 Festival' ? `
    
    `: pageTitle === '2019 Festival' ? `

    `: pageTitle === 'What is Asia?' ? `
        grid-column-start: 2;
        grid-column-end: 6;
        margin-bottom: 50px;
    `: pageTitle === 'Radio' ? `
      width: 67%;
      margin-left: -3vw;
      margin-top: 56px;
     
    ` : ``}
  }

`

const TextSection = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  eyebrow,
  text,
  paragraphSize,
  actions,
  alignment,
  isFirstSection,
  index,
  count,
  id,
  pageTitle
}) => {
  if (!text) {
    return false
  }
  const align = {
    // same as in WideMedia.jsx
    left: {
      medium: 'container',
      large: 'container',
      larger: 'container'
    },
    center: {
      medium: 'container',
      large: 'container',
      larger: 'container'
    },
    right: {
      medium: '7 [6] 1',
      large: '7 [6] 1',
      larger: '7 [5] 2'
    }
  }
  console.log('TextSection index:', index, typeof(index))
  if (!alignment || alignment === null) {
    alignment = 'left'
  }
  const vary = ( count % 3 + index ) % 2
  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      alignment={alignment}
      isFirstSection={isFirstSection}
      index={index}
      count={count}
      vary={vary}
      pageTitle={pageTitle}
    >
      <Grid
        small="1 [12] 1"
        medium="1 [12] 1"
        large="1 [12] 1"
        larger="1 [12] 1"
      >
        <TextLockup
          eyebrow={eyebrow}
          text={text}
          textSize={paragraphSize}
          actions={actions}
          theme={theme}
          stroke={pageTitle =='Festival'? false : true }
        />
      </Grid>
    </Wrapper>
  )
}

export default TextSection
