import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Video from 'src/components/Video'
import { GatsbyImage } from 'gatsby-plugin-image'
import TextLockup from 'src/components/TextLockup'
import { mq } from 'src/styles'

const imageSizes = {
  'large' : {
    width: '100%',
    min: '100%',
    max: '100%'
  },
  'medium' : {
    width: '70%',
    min: '120px',
    max: '250px'
  },
  'small' : {
    width: '40%',
    min: '60px',
    max: '140px'
  }
}

const Wrapper = styled(Section)`
  text-align: ${ ({ alignment }) => alignment };
`

const ColumnText = styled(TextLockup)`
  p {
    max-width: 28em;
  }
`

const MediaScrollEntrance = styled(ScrollEntrance)`
  display: inline-block;
  vertical-align: top;
  ${ ({ imageSize }) => imageSize ? `
    width: ${ imageSizes[imageSize].width };
    min-width: ${ imageSizes[imageSize].min };
    max-width: ${ imageSizes[imageSize].max };
  ` : `
    width: 100%;
  ` }
`

const ColumnWrapper = styled.div`
  .gatsby-image-wrapper,
  .video-wrapper {
    margin-bottom: 24px;
  }
`

const ColumnsGrid = styled.div`
  ${ ({ alignment, vAlign, smallCols, mediumCols, largeCols, colGap, rowGap }) => alignment === 'center' ? `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0 !important;
    align-items: ${ vAlign === 'bottom' ? 'flex-end' : vAlign };
    margin-left: calc(${ colGap[0] } / -2);
    margin-right: calc(${ colGap[0] } / -2);
    margin-bottom: -${ rowGap[0] };
    width: auto;
    ${ mq.mediumAndUp } {
      margin-left: calc(${ colGap[1] } / -2);
      margin-right: calc(${ colGap[1] } / -2);
      margin-bottom: -${ rowGap[1] };
    }
    ${ mq.largeAndUp } {
      margin-left: calc(${ colGap } / -2);
      margin-right: calc(${ colGap } / -2);
      margin-bottom: -${ rowGap[2] };
    }
    ${ ColumnWrapper } {
      width: ${ 100 / smallCols }%;
      padding-left: calc(${ colGap[0] } / 2);
      padding-right: calc(${ colGap[0] } / 2);
      margin-bottom: ${ rowGap[0] };
      ${ mq.mediumAndUp } {
        width: ${ 100 / mediumCols }%;
        padding-left: calc(${ colGap[1] } / 2);
        padding-right: calc(${ colGap[1] } / 2);
        margin-bottom: ${ rowGap[1] };
      }
      ${ mq.largeAndUp } {
        width: ${ 100 / largeCols }%;
        padding-left: calc(${ colGap[2] } / 2);
        padding-right: calc(${ colGap[2] } / 2);
        margin-bottom: ${ rowGap[2] };
      }
    }
  ` : `` }
`

const gridSetup = {
  1: '[1]',
  2: '[1] [1]',
  3: '[1] [1] [1]',
  4: '[1] [1] [1] [1]',
  5: '[1] [1] [1] [1] [1]',
  6: '[1] [1] [1] [1] [1] [1]'
}

const Columns = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  columns,
  alignment,
  verticalAlignment = 'top',
  id,
  desktopColumnCount = 3,
  tabletColumnCount = 2,
  mobileColumnCount = 1,
  imageSize
}) => {
  return (
    <Wrapper
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      alignment={alignment === null ? 'left' : alignment}
    >
      <Grid small="container" medium="container" large="container">
        <ColumnsGrid
          small={gridSetup[mobileColumnCount]}
          medium={gridSetup[tabletColumnCount]}
          large={gridSetup[desktopColumnCount]}
          smallCols={mobileColumnCount}
          mediumCols={tabletColumnCount}
          largeCols={desktopColumnCount}
          vAlign={verticalAlignment}
          alignment={alignment}
          rowGap={['7vw', '7vw', '80px']}
          colGap={['2vw', '2vw', '2vw']}
          as={alignment === 'center' ? 'div' : Grid}
        >
          {columns.map((column, index) => {
            if (column.media && column.media.gatsbyImageData) {
              column.media.gatsbyImageData.sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) ' + (86 / desktopColumnCount) + 'vw, (min-width: ' + mq.smallBreakpoint + 'px) ' + (86 / tabletColumnCount) + 'vw, ' + (86 / mobileColumnCount) + 'vw'
            }
            return (
              <ColumnWrapper
                alignment={alignment === null ? 'left' : alignment}
                index={index}
                colCount={columns.length}
                key={column.id + '-' + index + '-' + id}
              >
                {(column.media && column.media !== null) && (
                  <MediaScrollEntrance delay={index} imageSize={imageSize || 'large'}>
                    {column.media.file.contentType.includes('video') ? (
                      <Video video={column.media} />
                    ) : (
                      <GatsbyImage image={column.media.gatsbyImageData} alt={column.media.file.fileName}/>
                    )}
                  </MediaScrollEntrance>
                )}
                <ColumnText
                  entranceDelay={column.media ? index + 1 : index}
                  eyebrow={column.eyebrow}
                  text={column.text}
                  textSize={column.paragraphSize}
                  actions={column.actions}
                  theme={theme}
                  alignment={alignment}
                />
              </ColumnWrapper>
            )
          })}
        </ColumnsGrid>
      </Grid>
    </Wrapper>
  )
}

export default Columns
