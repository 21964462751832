import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'

import Section from 'src/components/Section'
import Grid from 'src/components/Grid'

import { colors, typography, animations, mq, globals } from 'src/styles'

const Wrapper = styled.div`
  ${ mq.mediumAndUp } {
      
    ${ ({ count, isFirstSection }) => count === 1 ? `
      flex-basis: 66.6666%;
      align-self: center;
    ` : `
        ${ isFirstSection ? `
        flex-basis: 33.3333%;
      
      ` : `
        flex-basis: 66.6666%;
        // ${ globals.verticalSpacing('padding-top', 0.5) }
        // align-self: flex-end;
        align-content: flex-start;
      `}
    ` }

    ${ ({ pageTitle }) => pageTitle === 'Partnerships' ? `
      grid-column: 1/-1;
    `: pageTitle === 'What is Asia?' ? `
      grid-column: 1/-1;
    `:``}
  }
  // background: chartreuse;
`

const GroupTitle = styled.h6`
  text-align: center;
`

const MediaImage = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
  ${ ({ height }) => height !== 'auto' ? `
    height: ${ height };
    > div {
      height: ${ height };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

const LogosWrap = styled.div`
    display: flex; 
    justify-content: space-around;
    flex-wrap: wrap;
    > div {
      margin: 20px 10px;
      padding: 0 20px;
      // flex: auto;
      flex: 1 0 20%;
      max-width: 200px;
      &:last-child {
        // flex-basis: 15%;
      }
      ${ ({ pageTitle }) => pageTitle === 'Partnerships' ? `

      `: pageTitle === 'What is Asia?' ? `
        max-width: 400px;
    `:``}
      ${ mq.mediumAndBelow } {
        max-width: 125px;
      }
    }
    ${ mq.mediumAndBelow } {
      justify-content: space-around;
    }
`

const LogoGroup = ({ className, title, logos, prevTheme, nextTheme, isFirstSection, index, count, pageTitle }) => {
  console.log('isFirstSection',isFirstSection);
  return (
    <Wrapper className={className} isFirstSection={isFirstSection} count={count} pageTitle={pageTitle}>

      <Grid
        small="1 [12] 1"
        medium="1 [12] 1"
        large="1 [12] 1"
        larger="1 [12] 1"
        vAlign="top"
      >
        <div>
          {title && (
            <GroupTitle>{title}</GroupTitle>
          )}
          
          <LogosWrap pageTitle={pageTitle}>
            {logos && logos.map((logo, index) => {
              return (
                <MediaImage
                  image={logo.gatsbyImageData}
                  loading={isFirstSection ? 'eager' : 'lazy'}
                  alt={logo.file.fileName}
                  key={index}
                  objectFit='contain'
                /> 
            )}
            )}
          </LogosWrap>
 
        </div>
        </Grid>
    </Wrapper>
  )
}

export default LogoGroup
