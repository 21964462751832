import React, { Component } from 'react'
import styled from '@emotion/styled'
import { colors, typography, animations, mq, util } from 'src/styles'

import { Link as GatsbyLink } from 'gatsby'

const LinkStyles = setTheme => `
	font-size: inherit;
	text-decoration: none;
	cursor: pointer;
	display: inline-block;
    padding: 3px 0;
    position: relative;
	&:after {
		content: '';
		position: absolute;
		left: 100%;
		width: 5px;
		height: 5px;
		transform: rotate(-45deg);
		border-bottom: 2px solid;
		border-right: 2px solid;
		border-color: ${ colors.mainColor };
		top: 50%;
		margin-top: -3px;
		opacity: 0;
		transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
	}
	// &:after {
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	top: 100%;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 2px;
	// 	background: ${ colors.black };
	// 	transform: scaleX(0);
	// 	transform-origin: right center;
	// 	transition: transform ${ animations.mediumSpeed } ease-in-out;
	// }
	// &:hover {
	// 	&:after {
	// 		transform-origin: left center;
	// 		transform: scaleX(1);
	// 	}
	// }
`

const StyledLinkElement = styled.a`
	${ ({ setTheme }) => `
		${ LinkStyles(setTheme) }
	` }
`

const StyledGatsbyLink = styled(GatsbyLink)`
	${ ({ theme }) => `
		${ LinkStyles(theme) }
	` }
`

class Link extends Component {
	render () {
		const { to, external, target, children, label, className, setTheme, title } = this.props
		if (external) {
			return (
				<StyledLinkElement
					title={title}
					className={className}
					href={to}
					target={target}
					theme={setTheme}
					rel='noopener noreferrer'
				>
					{children || label}
				</StyledLinkElement>
			)
		} else {
			return (
				<StyledGatsbyLink
					title={title}
					className={className}
					to={to}
					theme={setTheme}
				>
					{children || label}
				</StyledGatsbyLink>
			)
		}
	}
}

Link.defaultProps = {
	to: '#',
	external: false,
	target: '',
	setTheme: 'alert'
}

export default Link
