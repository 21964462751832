import React, { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import ReactPlayer from 'react-player'

import { GatsbyImage } from 'gatsby-plugin-image'
import { colors, typography, util, animations, mq } from 'src/styles'

const Wrapper = styled(Section)`
`
const RadioSectionWrap = styled.div`
  width: 100vw;
  padding: 20px;
  display: block;
  // display: flex;
  // flex-direction: column;
  // min-height: 90vh;
  height: 100%;
`
const TopRow = styled.div`
${ mq.mediumAndBelow } {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }	
  ${ mq.mediumAndUp } {
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 2fr;
    grid-auto-flow: row;
    grid-gap: 20px;
    grid-auto-rows: 0.3fr;
  }	
  ${ mq.mediumAndUp } {
    margin-bottom: 36px;
  }	

`
const MiddleRow = styled.div`
`

const BottomRow = styled.div`
  display: flex;
  min-height: 40vh;
  height: 100%;
  position: relative;
  ${ mq.mediumAndBelow } {
    flex-direction: column;
  }	
  ${ mq.mediumAndUp } {
    flex-direction: row;
  }	
`

const CoverImageContainer = styled.div`
  // ${ mq.mediumAndBelow } {
  //   flex-basis: 100%;
  //   margin-bottom: 20px;
  // }	
  // ${ mq.mediumAndUp } {
  //   flex-basis: 25%;

  //   min-width: 30vw;
  // }	

  grid-area: left;
`
const CoverImageWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    transition: visibility ${ animations.mediumSpeed } linear, opacity ${ animations.mediumSpeed } linear;
    ${ ({ active }) => active === true ? `
    visibility: visible;
    opacity: 1;
    ` : `
    visibility: hidden;
    ` }
`

const CoverImage = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
    > div {
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      ${ mq.mediumAndBelow } {
      }
      ${ mq.mediumAndUp } {
      }		
    }
    opacity: 0;
    object-fit: cover;
    transition: visibility ${ animations.mediumSpeed } linear, opacity ${ animations.mediumSpeed } linear ${ animations.mediumSpeed } ;
    ${ ({ active }) => active === true ? `
    display: block;
    opacity: 1;
    ` : `
    display: none;
    ` }
`

const ListWrap = styled.div`
  grid-area: right;
  overflow-y: scroll;
//     max-height: 40vh;
//  ${ mq.mediumAndBelow } {
//     display: block;
//     margin-bottom: 20px;
//   }	
//   ${ mq.mediumAndUp } {
//     padding-left: 10px;
//     flex-basis: 75%;
//   }	
`

const ListItem = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid ${ colors.black };
  ${ ({ active }) => active && `
    background: ${ colors.lightGrey };
  `}
  &:hover {
    cursor: pointer;
  }
`

const PlayerEmbed = styled(ReactPlayer)`
  width: 100%;
  height: auto;
`

const DescriptionContainer = styled.div`
  position: relative;
  ${ mq.mediumAndBelow } {
    flex-basis: 100%;
    margin-bottom: 20px;
  }	
  ${ mq.mediumAndUp } {
    flex-basis: 60%;
  }	
`

const DescriptionWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: visibility ${ animations.mediumSpeed } linear, opacity ${ animations.mediumSpeed } linear;
  ${ ({ active }) => active === true ? `
    visibility: visible;
    opacity: 1;
  ` : `
    visibility: hidden;
  ` }
`
const PlaylistContainer = styled.div`
  position: relative;
  ${ mq.mediumAndBelow } {
    flex-basis: 100%;
  }	
  ${ mq.mediumAndUp } {
    margin-left: 20px;
    flex-basis: 40%;
  }	
`

const Title = styled.h3`
  margin-bottom: 4px;
  font-weight: bold;
`
const Author = styled.h4`
  margin: 0;
  font-weight: normal;
`

const RadioSection = ({ className, list }) => {
  const FeaturedItem = list[list.length - 1]

  const [activeTab, setActiveTab] = useState(0)
  let activeItem = list[activeTab]
  const onClick = useCallback((index) => {
    setActiveTab(index)
  }, [activeTab]);
  const selectItem = useCallback((index) => {
    return (
      <GatsbyImage
        image={list[index].coverImage.gatsbyImageData}
        loading='eager'
        alt={list[index].coverImage.file.fileName}
      />
    )
  }, [activeTab]);

  console.log('activeItem:', activeItem)
  return (
    <Wrapper className={className}>
      <RadioSectionWrap>
      <TopRow>
        <CoverImageContainer>
          {list.map((item, index) => {
              return (
                <CoverImage
                  active={activeTab === index ? true : false} 
                  key={index}
                  image={item.coverImage.gatsbyImageData}
                  loading='eager'
                  alt={item.coverImage.file.fileName}
                />
              )
            })}
        </CoverImageContainer>
        <ListWrap>
          {list.map((item, index) => {
            return (
              <ListItem onClick={() => onClick(index)} active={activeTab === index ? true : false} key={index}>
                <Title>{item.title}</Title>
                <Author>{item.author}</Author>
              </ListItem>
            )
          })}
        </ListWrap>
      </TopRow>
      <MiddleRow>
        <PlayerEmbed
          url={activeItem.linkUrl}
          controls
          width='100%'
          height='100%'
        />
      </MiddleRow>
      <BottomRow>
          <DescriptionContainer>
            <TextLockup
              text={activeItem.description}
              stroke={true}
              tabGroup={false}
            />
          </DescriptionContainer>
          <PlaylistContainer>
            <TextLockup
              text={activeItem.playlist}
              stroke={true}
              tabGroup={false}
            />
          </PlaylistContainer>
      </BottomRow>
      </RadioSectionWrap>
    </Wrapper>
  )
}
export default RadioSection
