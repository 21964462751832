import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'

import { colors, typography, mq, globals, animations } from 'src/styles'


const Wrapper = styled(Section)`
  ${mq.mediumAndBelow} {
    padding: 0 16px 56px;
  }
  ${ mq.mediumAndUp } {
    flex-basis: 66.6666%;
    ${ ({ isFirstSection }) => isFirstSection === true ? `
      ` : `
      ${ globals.verticalSpacing('padding-top', 0.5) }
  
    ` }

    ${ ({ pageTitle, index }) => pageTitle === 'Home' ? `
  
    ` : pageTitle === 'Archive' ? `
      order: 2;
    ` : pageTitle === 'About' ? `

    `: pageTitle === 'Press' ? `
      order: 3;
      grid-column-start: 2;
      padding-left: 32px;
    `: pageTitle === 'Co-Op' ? `
      order: 5;
      grid-column-start: 2;
      padding-left: 10px;
    `: pageTitle === 'Donate' ? `
    
    `: pageTitle === 'Partnerships' ? `

    `: pageTitle === 'Team' ? `

    `: pageTitle === '2018 Festival' ? `
    
    `: pageTitle === '2019 Festival' ? `
    
    ` :``}
  }
`

const ListWrap = styled.div`

`

const StyledList = styled.ul`
  padding: 0 16px;
`
const Title = styled.p`
  ${ typography.eyebrow }
  ${ mq.mediumAndUp } {
    margin-top: 0;
  }
`

const CompactList = styled.div`

`

const CompactItem = styled.div`
  // margin-left: 16px;
  // display: inline;
  // &:before {
  //   content: '*';
  //   // content: "\2609";
  //   // content: '\2022';
  // }
`

const StyledLi = styled.li`
  padding: 9px 0;
  list-style-type: circle;
  &:first-of-type {
    padding-top: 0;
  }
`

const StyledLink = styled(Link)`
  span {
    border-bottom: 1px solid ${ colors.lightTextColor };
    transition: border ${ animations.mediumSpeed } ease-in-out;
      &:hover {
        border-color: ${ colors.orange };
      }
  }
  padding: 0;
`

const StyledCompactLink = styled(Link)`
  // color: red;
  // background: chartreuse;
  &:before {
	}
  margin-left: 7px;
  span {
    margin-left: 8px;
    border-bottom: 1px solid ${colors.transparent};
    ${ typography.compactLink }
    transition: border ${ animations.mediumSpeed } ease-in-out;
    &:hover {
      border-bottom: 1px solid ${colors.orange};
    }
  }
  ${ ({ index }) => index === 0 ? `
    span {
      margin-left: 0;
    }
    ` : `` }
  &:first-of-type {
    margin-left: 0;
  }
  display: inline;
  // border-bottom: 1px solid ${ colors.lightGrey };
  
`

const LinkList = ({ 
  className,
  theme,
  prevTheme,
  nextTheme,
  title,
  listType,
  links,
  isFirstSection,
  id,
  pageTitle,
  index 
}) => {
  if ( listType === 'Compact' ) {
    return (
      <Wrapper className={className} isFirstSection={isFirstSection} pageTitle={pageTitle} index={index}>
        <Grid
            small="[14]"
            medium="[14]"
            large="[14]"
            larger="[14]"
          >
        <CompactList>
          <Title>{title}</Title>
        {links.map((link, index) => {
          if (index ===0 ){
            return (
                <StyledCompactLink
                  className={'button ' + className}
                  to={link.to}
                  target={link.target}
                  external={link.external}
                  theme={link.theme}
                  title={link.label}
                  index={index}
                >
                    <span>{link.label}</span>
                </StyledCompactLink>
            )
          } else {

            return (
                <StyledCompactLink
                  className={'button ' + className}
                  to={link.to}
                  target={link.target}
                  external={link.external}
                  theme={link.theme}
                  title={link.label}
                  index={index}
                >
                    &#8226;<span>{link.label}</span>
                </StyledCompactLink>
            )
          }
        })}
        </CompactList>
        </Grid>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper className={className} isFirstSection={isFirstSection} pageTitle={pageTitle} index={index}>
        <Grid
            small="1 [12] 1"
            medium="1 [13]"
            large="1 [13]"
            larger="1 [13]"
          >
        <StyledList>
          <Title>{title}</Title>
        {links.map((link, index) => {
          return (
            <StyledLi>
              <StyledLink
                className={'button ' + className}
                to={link.to}
                target={link.target}
                external={link.external}
                theme={link.theme}
                title={link.label}
              >
                <span>{link.label}</span>
              </StyledLink>
            </StyledLi>
          )
        })}
        </StyledList>
        </Grid>
      </Wrapper>
    )
  }
  
}

export default LinkList
