import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactPlayer from 'react-player'
import Video from 'src/components/Video'
import Link from 'src/components/Link'
import ContentfulRichText from 'src/components/ContentfulRichText'
import TextLockup from 'src/components/TextLockup'


import { typography, mq, colors, util, animations } from 'src/styles'

const Wrapper = styled.div`
  ${ mq.mediumAndBelow } {
    padding: 0 2px;
  }
  padding: 0 7px;
`

const MediaImage = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
  ${ ({ height }) => height !== 'auto' ? `
    height: ${ height };
    > div {
      height: ${ height };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

const StyledVideo = styled(ReactPlayer)`
  z-index: 10;
  position: relative;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  width: 100% !important;
  height: auto !important;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  ${ ({ cover }) =>
    cover &&
    `
		video {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	` }
  ${ ({ url, cover }) =>
    url.indexOf('youtube') > -1 || url.indexOf('vimeo') > -1
      ? `
		padding-bottom: ${ (9 / 16) * 100 }%;
		${ cover &&
      `
			position: absolute;
			top: 50%;
			left: 50%;
			padding: 0;
			width: 100% !important;
			height: 100% !important;
			@media (min-aspect-ratio: 16/9) {
				// tall
				min-width: 100vw;
				min-height: 56.25vw;
				margin-left: -50vw;
				margin-top: -28.125vw;
			}
			@media (max-aspect-ratio: 16/9) {
				// wide
				min-width: 177.77vh;
				min-height: 100vh;
				margin-left: -88.885vh;
				margin-top: -50vh;
			}
			min-width: 0;
			min-height: 0;
		` }
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	`
      : `
	` }
`
const VideoWrapper = styled.div`
  ${ ({ cover }) =>
    cover &&
    `
		height: 100%;
	` }
`

const MediaVideo = styled(Video)`
  position: relative;
  z-index: 1;
  ${ ({ height }) => height !== 'auto' ? `
    > div {
      height: ${ height };
      min-height: 40vw;
      position: relative;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      // height: 100%;
    }
  ` : `` }
`

const QuoteWrapper = styled.div`
    // padding: 0 5px;
    padding-top: 20px;
    text-align: center;

`

const Author = styled.p`
    margin-top: 5px;
    margin-bottom: 0;
    ${ typography.bodyMedium}
    font-size: 15px !important;
`

const TextWrapper = styled.div`
  padding-top: 20px;
  text-align: center;
  p {
    font-family: ${ typography.secondaryFont} !important;
    ${ mq.smallAndBelow } {
      font-size: 11px !important;
    }
  }
  
`

const StyledLink = styled(Link)`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
`

const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;

const Slide = ({ className, title, image, description, croppedImage, videoType, videoLoop, videoId, quote, author, linkTarget }) => {
  // console.log('SLIDEEEEEE----videoLoop: ', videoLoop)
  // console.log('SLIDEEEEEE----author: ', author)
  
  return (
  <Wrapper className={className}>
    <ConditionalWrapper
      condition={linkTarget}
      wrapper={children =>  <StyledLink to={linkTarget}>{children}</StyledLink>}
    >
      {/* {image && 
        <MediaImage
          image={image.gatsbyImageData}
          alt={image.file.fileName}
        />
        // <Img fluid={image.fluid} loading="eager" />
      } */}

      {videoLoop && (
        <MediaVideo
          video={videoLoop}
          playing={true}
          loop={true}
          height={'20vh'}
          // posterImage={mediaItem.posterImage}
          autoplay={false}
        />

      )}
      
      {/* {videoId && (
        <VideoWrapper>
          <StyledVideo url={'https://vimeo.com/' + videoId} controls={true} />
        </VideoWrapper>
      )} */}
      {quote && (
        <QuoteWrapper>
            <ContentfulRichText richText={quote.quote}/>
            <Author>{author}</Author>
        </QuoteWrapper>
      )}

      {description && (
        <TextWrapper>
          <TextLockup
            text={description}
            stroke={true}
            tabGroup={false}
            textSize={'bodySmall'}
          />
        </TextWrapper>
      )}

    </ConditionalWrapper>
  </Wrapper>
)}

export default Slide
