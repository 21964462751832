import { React, useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import anime from "animejs/lib/anime.es.js"
// import Anime, { anime } from 'react-anime';
import ReactAnime from 'react-animejs'
const {Anime, stagger} = ReactAnime
import { GatsbyImage } from 'gatsby-plugin-image'
import ResponsiveComponent from 'src/components/ResponsiveComponent'


import Section from 'src/components/Section'
import { typography, mq, colors, animations, globals } from 'src/styles'

const Wrapper = styled(Section)`
  ${ mq.mediumAndUp } {
    ${ ({ isFirstSection, index }) => isFirstSection === true ? `
      // flex-basis: 33.3333%;
    ` : `
      // flex-basis: 66.666%;
    ` }
    height: 100%;
    // padding: 20px;
    ${ ({ pageTitle, index }) => pageTitle === 'Home' ? `
  
    ` : pageTitle === 'Archive' ? `
      // order: 2;
    ` : pageTitle === 'About' ? `
    grid-column-start: 2;

    `: pageTitle === 'Press' ? `
      &:first-of-type {
        order: 1;
      }
      &:last-of-type {
        order: 2;
      }
      svg {
        margin-top: 20px;
      }
    `: pageTitle === 'Co-Op' ? `
      order: 3;
    `: pageTitle === 'Donate' ? `
        ${ index === 2 ? `
          order: 3;
        ` : `
          order: 6;
        `}
    `: pageTitle === 'Partnerships' ? `

    `: pageTitle === 'Team' ? `

    `: pageTitle === '2018 Festival' ? `
    
    `: pageTitle === '2019 Festival' ? `
      grid-column-start: 2;
      margin-top: -5vh;
    ` :``}
  }
  
`

const CroppedSvg = styled.svg`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
`

const ClipShape = styled.path`
`

const SvgWrapper = styled.div`
  // background: chartreuse;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    margin-top: -50px;
    // max-width: 600px;
    // min-height: 600px;
  }
  ${mq.mediumAndBelow} {
    padding: 20px;
  }
  
`

const MediaImage = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
  ${ ({ height }) => height !== 'auto' ? `
    height: ${ height };
    > div {
      height: ${ height };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

// shape1 : landscape friendly, last one [home]
// shape2 : portrait friendly [co-op]
// shape3: square friendly

const pathSet = {
  shape1: {
    initialPath: "M67,-40.3C76.6,-22,67.1,5.6,52.8,25.5C38.4,45.3,19.2,57.4,-4,59.7C-27.1,62,-54.3,54.5,-68.4,34.8C-82.5,15,-83.6,-16.9,-70,-37.4C-56.4,-57.9,-28.2,-67,0.2,-67.1C28.7,-67.3,57.4,-58.5,67,-40.3Z",
    morphPath: "M65.1,-35.4C78.4,-14.6,79.1,15.7,66.1,33.7C53.1,51.8,26.6,57.5,-0.5,57.8C-27.6,58.1,-55.1,52.9,-66.2,36C-77.2,19.1,-71.8,-9.6,-58,-30.6C-44.2,-51.7,-22.1,-65.1,1.9,-66.2C25.9,-67.4,51.8,-56.1,65.1,-35.4Z",
    deco: "M30.3,-41.8C45.6,-30.6,68.6,-30.2,78.1,-20.7C87.5,-11.3,83.3,7.2,73,19C62.7,30.9,46.4,36.1,33.2,43C20,50,10,58.7,-3.8,64C-17.6,69.2,-35.2,71,-49.5,64.4C-63.9,57.8,-74.9,42.9,-76.6,27.3C-78.4,11.8,-70.8,-4.4,-62.4,-17.2C-54,-29.9,-44.7,-39.3,-34.1,-52.1C-23.6,-64.8,-11.8,-81,-2.1,-78.1C7.5,-75.1,15,-53.1,30.3,-41.8Z",
    translateValue : "translate(80 85)",
    scale: "4"
  },
  shape2: {
    initialPath: "M64,-39.6C73.1,-21,64.1,5.4,50.4,24.1C36.7,42.9,18.3,54,1.4,53.2C-15.6,52.4,-31.1,39.7,-39.4,24.1C-47.6,8.4,-48.6,-10.1,-40.8,-27.9C-33,-45.7,-16.5,-62.8,5.4,-65.9C27.4,-69.1,54.8,-58.3,64,-39.6Z",
    morphPath: "M53.8,-29.9C65.6,-10.7,68.1,15.1,57.6,36.3C47.1,57.5,23.6,74.1,4,71.9C-15.6,69.6,-31.3,48.3,-41.6,27.3C-51.9,6.2,-56.8,-14.7,-48.9,-31.7C-41.1,-48.7,-20.6,-61.7,0.2,-61.8C21,-62,42,-49.2,53.8,-29.9Z",
    deco: "M38.8,-63.7C43.9,-57.1,37.3,-35.6,34.6,-21.6C32,-7.6,33.4,-1.3,33.3,5.3C33.2,12,31.6,19,27.4,23.6C23.2,28.2,16.4,30.3,9.8,31.5C3.1,32.6,-3.3,32.8,-15.4,36.5C-27.5,40.2,-45.4,47.5,-53.1,43.3C-60.8,39.2,-58.4,23.6,-59.4,9.2C-60.5,-5.2,-65.1,-18.5,-64.4,-33.3C-63.7,-48.1,-57.8,-64.4,-46.2,-68C-34.6,-71.6,-17.3,-62.4,-0.2,-62.1C16.9,-61.8,33.7,-70.3,38.8,-63.7Z",
    translateValue : "translate(80 85)",
    scale: "4"
  },
  shape3: {
    initialPath: "M65.8,-52.3C81.1,-33.2,86.5,-6.1,79.9,16.4C73.3,38.9,54.6,56.8,33.2,66.1C11.7,75.4,-12.6,75.9,-33.2,66.8C-53.9,57.7,-70.9,38.9,-75.6,17.5C-80.2,-3.9,-72.6,-27.8,-58,-46.7C-43.3,-65.6,-21.7,-79.5,1.8,-80.9C25.2,-82.3,50.4,-71.3,65.8,-52.3Z",
    morphPath: "M61.9,-52.3C74.9,-33.1,76.4,-7.7,70.3,14.8C64.2,37.3,50.5,56.9,31.1,67.3C11.7,77.7,-13.5,78.9,-32.1,69C-50.7,59.2,-62.7,38.1,-69.1,14.5C-75.6,-9.1,-76.3,-35.3,-63.8,-54.3C-51.4,-73.4,-25.7,-85.3,-0.6,-84.8C24.5,-84.3,49,-71.4,61.9,-52.3Z",
    deco: "M30.3,-41.8C45.6,-30.6,68.6,-30.2,78.1,-20.7C87.5,-11.3,83.3,7.2,73,19C62.7,30.9,46.4,36.1,33.2,43C20,50,10,58.7,-3.8,64C-17.6,69.2,-35.2,71,-49.5,64.4C-63.9,57.8,-74.9,42.9,-76.6,27.3C-78.4,11.8,-70.8,-4.4,-62.4,-17.2C-54,-29.9,-44.7,-39.3,-34.1,-52.1C-23.6,-64.8,-11.8,-81,-2.1,-78.1C7.5,-75.1,15,-53.1,30.3,-41.8Z",
    translateValue : "translate(90 90)",
    scale: "3.75"
  }
}


const CroppedImage = ({i, className, image, cropStyle, shapeColor, isFirstSection, index, count, prevTheme, nextTheme, pageTitle}) => {
  // console.log('Cropped Image :', prevTheme, nextTheme)
    
    
    // console.log('CROPPED IMAGE:', cropStyle)
    const thisCrop = pathSet[cropStyle]
    return(
      <ResponsiveComponent
        small={
          <SvgWrapper>

            <svg viewBox="0 -50 700 700">
              <defs>
                  <clipPath id="maskImage">
                      <path id="maskPath" d={pathSet[cropStyle].initialPath} transform={"scale(4) " + pathSet[cropStyle].translateValue }/>
                  </clipPath>
              </defs>
              <g className="morph_deco">
                  <path id="deco" fill={colors[shapeColor]} d={pathSet[cropStyle].deco} transform="scale(4) translate(80 80)" />
              </g>
              <image clipPath="url(#maskImage)" className="clipped-img" href={image.file.url } width="100%" height="100%"  preserveAspectRatio='xMidYMid slice' />
            
            </svg>
          </SvgWrapper>
        }
        medium={
          <Wrapper className= {className} isFirstSection={isFirstSection} index={index} pageTitle={pageTitle}>
          <Anime
            _onMouseEnter={[
              {
                targets: "#maskPath",
                // easing: "linear",
                easing: 'easeOutCubic',
                duration: 2000,
                d: [{
                  value: pathSet[cropStyle].morphPath
                }],
              }
            ]}
            _onMouseLeave={[
              {
                targets: "#maskPath",
                // easing: "linear",
                easing: 'easeOutCubic',
                duration: 2000,
                d: [{
                  value: pathSet[cropStyle].initialPath
                }],
              }
            ]}
          >
            <SvgWrapper>

            <svg viewBox="0 -50 700 700">
                <defs>
                    <clipPath id="maskImage" >
                        <path id="maskPath" d={pathSet[cropStyle].initialPath} transform={"scale(" + pathSet[cropStyle].scale + ")" + pathSet[cropStyle].translateValue }/>
                    </clipPath>
                </defs>
                <g className="morph_deco">
                    <path id="deco" fill={colors[shapeColor]} d={pathSet[cropStyle].deco} transform="scale(4) translate(90 80)" />
                </g>
                <image clipPath="url(#maskImage)" className="clipped-img" href={image.file.url } width="100%" height="100%"  preserveAspectRatio='xMidYMid slice' />
              
              </svg>
            </SvgWrapper>
          </Anime>
        </Wrapper>
        }
      />
        
        
    )

}


export default CroppedImage




// import React from 'react'
// import styled from '@emotion/styled'

// const Wrapper = styled.div``

// const CroppedImage = ({ className }) => (
//   <Wrapper className={className}>CroppedImage</Wrapper>
// )

// export default CroppedImage


// <div class="morph" data-animation-path-duration="1600" data-animation-path-easing="easeInOutBack" data-path-scaleX=".9" data-path-scaleY=".9" data-morph-path="M59.3,-46.9C74.5,-28.3,82.7,-3.7,76.8,15.7C71,35.2,50.9,49.6,28.7,60.8C6.5,71.9,-17.9,79.8,-38.1,72.6C-58.4,65.3,-74.5,43,-74.3,22.9C-74.1,2.8,-57.7,-15,-42.6,-33.6C-27.4,-52.3,-13.7,-71.7,4.2,-75C22.1,-78.3,44.1,-65.6,59.3,-46.9Z" data-animation-deco-duration="2000"> 
//     <svg class="morph_svg" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
//         <clipPath id="clipShape1">
//             <path class="morph_path" d="M56.5,-49.1C63.8,-35.9,53.8,-12.s9,48.1,10.4C42.3,33.7,40.6,57.4,29.5,63.6C18.4,69.9,-2.2,58.7,-14,46.4C-25.7,34.1,-28.5,20.6,-37.4,2.5C-46.3,-15.5,-61.3,-38.2,-56.1,-50.9C-50.9,-63.7,-25.4,-66.5,-0.4,-66.2C24.6,-65.9,49.2,-62.4,56.5,-49.1Z" transform="translate(100 100)" />
//         </clipPath>
//         <g class="morph_deco" style="transform:translate(-10 -10) scaleX(1.1) scaleY(1.1)">
//             <use xlink:href="#deco6"></use>
//         </g>
//         <g clip-path="url(#clipShape1)">
//             <image  xlink:href="_ARX7951.jpg" x="0" y="0" height="200px" width="200px"></image>
//         </g>
//     </svg>
//     <div class="morph_caption">
//         <p class="morph_title">
//             Easing: InOutBack <br> Duration: 1600
//         </p>
//     </div>
// </div>

// targets: {ClipShape},
// d: [{
//     path: "M57.2,-47.8C68.3,-31.8,67.2,-8.3,58.6,6.9C50,22.2,33.7,29.3,15.5,41.8C-2.8,54.3,-23.1,72.2,-40.3,69.4C-57.4,66.6,-71.5,43,-68.1,25C-64.7,7,-43.7,-5.6,-29.5,-22.3C-15.2,-39,-7.6,-59.8,7.8,-66C23.1,-72.2,46.2,-63.7,57.2,-47.8Z",
//     secondpath: "M49,-35.3C63.9,-20.3,76.7,0,73.6,18C70.5,36,51.4,51.7,32,57.6C12.6,63.6,-7.3,59.9,-20.7,50.5C-34.1,41.2,-41.2,26.1,-48.7,7.2C-56.1,-11.7,-63.9,-34.5,-55.9,-47.8C-47.8,-61.2,-23.9,-65.3,-3.4,-62.5C17,-59.8,34.1,-50.3,49,-35.3Z"
// }],
// duration: 2500,
// delay: 100,
// easing: "OutElastic",
// elasticity: "600",
// scaleX: 1,
// scaleY: 1,
// rotate: 0,
// translateX:100,