import React from 'react'
import styled from '@emotion/styled'
import Video from 'src/components/Video'
import { typography, util, animations, mq } from 'src/styles'
import ReactPlayer from 'react-player'

const Wrapper = styled.div`
  ${ mq.smallAndBelow } {
    width: 100vw;
    margin: 20px 0 50px;
  }
`

const StyledVideo = styled(ReactPlayer)`
  z-index: 10;
  position: relative;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  width: 100% !important;
  height: auto !important;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  padding-bottom: ${ 9 / 16 * 100 }%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`

const VideoEmbed = ({ className, videoType, videoId }) => {
  let parsedSrc = '';
  if (videoType === 'vimeo') {
    parsedSrc = 'https://vimeo.com/' + videoId
  } else {
    parsedSrc = 'https://www.youtube.com/watch?v=' + videoId
  }
  return (
    <Wrapper className={className}>
      <StyledVideo 
        controls={true}
        url={parsedSrc}
      />
    </Wrapper>
  )
}

export default VideoEmbed
