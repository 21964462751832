import React from 'react'
import TextSection from 'src/components/TextSection'
import Columns from 'src/components/Columns'
import WideMedia from 'src/components/WideMedia'
import FiftyFifty from 'src/components/FiftyFifty'
import Accordion from 'src/components/Accordion'
import TabGroup from 'src/components/TabGroup'
import CroppedImage from 'src/components/CroppedImage'
import LinkList from 'src/components/LinkList'
import Carousel from 'src/components/Carousel'
import PressQuote from 'src/components/PressQuote'
import RadioSection from 'src/components/RadioSection'
import LogoGroup from 'src/components/LogoGroup'
import FilmEntry from 'src/components/FilmEntry'
import VideoEmbed from 'src/components/VideoEmbed'
import GiftGuide from 'src/components/GiftGuide'

const componentMap = {
	ContentfulTextSection: TextSection,
	ContentfulColumns: Columns,
	ContentfulFiftyFifty: FiftyFifty,
	ContentfulWideMedia: WideMedia,
	ContentfulAccordion: Accordion,
	ContentfulTabGroup: TabGroup,
	ContentfulCroppedImage: CroppedImage,
	ContentfulLinkList: LinkList,
	ContentfulCarousel: Carousel,
	ContentfulQuoteGroup: PressQuote,
	ContentfulRadioList: RadioSection,
	ContentfulLogoGroup: LogoGroup,
	ContentfulFilmEntry: FilmEntry,
	ContentfulVideoEmbed: VideoEmbed,
	ContentfulGiftGuide: GiftGuide
}

export default ({ item, prevTheme, nextTheme, index, isLastSection, isFirstSection, count, pageTitle }) => {
	const Component = componentMap[item.__typename]
	return Component ? (
		<Component
			{...item}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			isLastSection={isLastSection}
			isFirstSection={isFirstSection}
			index={index}
			count={count}
			pageTitle={pageTitle}
		/>
	) : null
}
