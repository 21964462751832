import React from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import ThemeSelector from 'src/components/ThemeSelector'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Button from 'src/components/Button'

import { colors, typography, util } from 'src/styles'
import { Transition } from 'react-transition-group'
// import NewsletterSignup from 'src/components/NewsletterSignup'
import MailchimpSignup from 'src/components/MailchimpSignup'

const timeout = 300
const timingFunction = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const Wrapper = styled(ThemeSelector)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	padding-bottom: 50px;
	z-index: 3;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	background: ${ colors.lightGrey };
	${ util.responsiveStyles('padding-top', 113, 105, 90, 100) }
	transition: opacity 500ms ${ timingFunction }, transform ${ timeout }ms ${ timingFunction };
	transform: translate3d(0%, 110%, 0);
	opacity: 1;
	${ ({ transitionStatus }) => transitionStatus === 'exited' ? `
		// transform: translate3d(0%, 110%, 0);
		opacity: 0;
	` : `` }
	${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
		transform: none;
		opacity: 1;
	` : `` }
`

const MobileNavLink = styled(Link)`
	flex-grow: 0;
	flex-shrink: 0;
	${ typography.h4 }
	font-weight: normal;
	text-transform: uppercase;
	
	display: block;
	padding: 2px 0;
	color: ${ colors.textColor };
	span {
		display: inline-block;
	}
`

const MainSection = styled.div`
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	// align-items: stretch;
	// padding: 5vw 0 0;
`
const NavRow = styled.div`
	display: flex;
	flex-grow: 0;
	justify-content: center;
	align-items: center;
	${ ({ tall }) => tall === true ? `
		min-height: 4.5em;
	` : `
		min-height: 3em;
	` }
	&:first-of-type {
		border-top: 1px solid ${ colors.black };
	}
	&:not(:last-of-type) {
		border-bottom: 1px solid ${ colors.black };
	}
`

const BottomSection = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	padding-bottom: 12vw;
	p a {
		border-color: transparent;
	}
	b {
		color: ${ colors.mainColor };
	}
`

const LinkWrap = styled.div`
	flex-grow: 1;
	min-height: 3em;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	${ ({ split }) => split === true ? `
		&:last-of-type {
			border-left: 1px solid black;
		}
	` : `` }
`

const SubLinks = styled.ul`
	margin-bottom: 20px;
	padding-left: 0;
	list-style: none;
	margin-top: 3px;
	li {
		a {
			display: block;
			${ typography.bodyMedium }
			font-weight: 600;
			padding: 1px 0;
		}
		&:first-of-type {
			a {
				padding-top: 0;
			}
		}
		&:last-of-type {
			a {
				padding-bottom: 0;
			}
		}
	}
`

const MobileMenu = ({
	open,
	toggleMobileMenu,
	navLinks = [],
	footerColumn1,
	footerColumn2,
	pathname
}) => {
	const rowOne = navLinks.slice(0, 2)
	const rowTwo = navLinks.slice(2, 3)
	const rowThree = navLinks.slice(3)
  return (
    <Transition
      in={open}
      timeout={{
        enter: 1,
        exit: timeout
      }}
      unmountOnExit
    >
      {transitionStatus => (
        <Wrapper transitionStatus={transitionStatus}>
          <MainSection>
			  	<NavRow>
				  {rowOne.map((link, index) => (
					  <LinkWrap split={true} key={index}>
						<MobileNavLink to={link.to.to || '/' + link.to.linkToPage.slug}>
							<span onClick={() => pathname === link.to.linkToPage.slug ? toggleMobileMenu(false) : false}>{link.label}</span>
						</MobileNavLink>
					  </LinkWrap>
				))}
				</NavRow>
				<NavRow tall={true}>
					{rowTwo.map((link, index) => (
						<LinkWrap key={index}>
							<MobileNavLink to={link.to.to || '/' + link.to.linkToPage.slug}>
								<span onClick={() => pathname === link.to.linkToPage.slug ? toggleMobileMenu(false) : false}>{link.label}</span>
							</MobileNavLink>
						</LinkWrap>
					))}
				</NavRow>
				<NavRow tall={true}>
					{rowThree.map((link, index) => (
						<LinkWrap split={false} key={index}>
							<MobileNavLink to={link.to.to || '/' + link.to.linkToPage.slug}>
								<span onClick={() => pathname === link.to.linkToPage.slug ? toggleMobileMenu(false) : false}>{link.label}</span>
							</MobileNavLink>
						</LinkWrap>
					))}
				</NavRow>
				<NavRow tall={true}>
						<LinkWrap split={false}>
							<MobileNavLink to='/donate'>
							<Button
								setTheme='default'
								to='/donate'
								external={false}
								target={''}
								title='Donate'
								name='Donate'
								shape="pill"
								size="small"
								onClick={() => toggleMobileMenu(false)}
							>
								Donate
							</Button>
							</MobileNavLink>
						</LinkWrap>
				</NavRow>
          </MainSection>
          <BottomSection>
            <Grid small="1 [12] 1" rowGap="3vw">
				<MailchimpSignup></MailchimpSignup>
            </Grid>
          </BottomSection>
        </Wrapper>
      )}
    </Transition>
  )
}

export default MobileMenu
