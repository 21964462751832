import React from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import ScrollEntrance from 'src/components/ScrollEntrance'
import ContentfulRichText from 'src/components/ContentfulRichText'
import { typography, mq, colors } from 'src/styles'
import { themes } from 'src/styles/themes'

const Wrapper = styled.div`
	display: inline-block;
	display: block;
	vertical-align: top;
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
		> div {
			margin-left: auto;
			margin-right: auto;
		}
	` }
	${ mq.mediumAndBelow } {
		display: block;
	}
	// background: ${props => props.setTheme ? themes[props.setTheme].background : "transparent"};
	
	// background-color: ${ themes[setTheme].background };
`
const TextContainer = styled(ScrollEntrance)`
	text-align: ${ ({ alignment }) => alignment };
	width: 100%;
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }
	
`

const TextWrapper = styled.div`
	${ ({ stroke }) => stroke === true && `
		border: 1px solid ${ colors.black };
	` }
	${ ({ tabGroup }) => tabGroup === true ? `
	` : `
		padding: 24px 16px 36px;
	` }
	background: ${props => props.setTheme ? themes[props.setTheme].background : "transparent"};


	${ ({ wideMedia }) => wideMedia === true ? `
		padding: 36px 36px 54px;
		p {
			max-width: 32rem;
		}
	` : `
		// background-color: chartreuse;
	` }
	
`

const Eyebrow = styled.p`
	${ typography.eyebrow }
	${ ({ hasText }) => hasText ? `
		margin: 0 0 0.5em;
	` : `
		margin: 0;
	` }
	${ ({ alignment }) => alignment === 'center' && `
		margin-left: auto;
		margin-right: auto;
	` }
	${ ({ alignment }) => alignment === 'right' && `
		margin-left: auto;
	` }
`

const Headline = styled.h3`
	${ ({ headlineSize }) => typography[headlineSize] }
	${ ({ hasText }) => !hasText && `
		margin-bottom: 0;
	` }
	${ ({ hasEyebrow }) => !hasEyebrow && `
		margin-top: 0;
	` }
`

const Text = styled.div`
	p {
		${ mq.mediumAndUp } {
			${ ({ textSize }) => typography[textSize] }
		}
		${ mq.smallAndBelow } {
			${typography.bodyMedium}
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
	}
`

const ActionWrapper = styled.div`
	padding: 16px 10px 0;
	display: flex;
	justify-content: center;
	vertical-align: middle;
`

const ButtonActions = styled.div`
	margin-top: 36px;
	text-align: ${ ({ alignment }) => alignment };
	margin-left: -10px;
	margin-right: -10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const TextLockup = ({
		theme = 'default',
		eyebrow,
		headline,
		headlineSize,
		headlineElement,
		text,
		textSize,
		actions,
		className,
		icon,
		alignment,
		additions,
		entranceDelay,
		transitionIn,
		stroke,
		tabGroup,
		wideMedia
	}) => {
		console.log('text lockup, theme:', theme)
	
	return (
		<Wrapper className={className} alignment={alignment} setTheme={theme} stroke={stroke === false ? false : true}>
			<div>
				<TextContainer alignment={alignment} delay={entranceDelay} transitionIn={transitionIn}>

					{eyebrow && (
						<div>
							<Eyebrow hasText={headline || text} alignment={alignment}>{eyebrow}</Eyebrow>
						</div>
					)}
					<TextWrapper stroke={stroke === false ? false : true} tabGroup={tabGroup} wideMedia={wideMedia} setTheme={theme}>
					{headline && (
						<Headline as={headlineElement || headlineSize} headlineSize={headlineSize} hasText={text} hasEyebrow={eyebrow}>{headline}</Headline>
					)}

					{text && text.raw &&
						<Text textSize={textSize} alignment={alignment}><ContentfulRichText richText={text}/></Text>
					}

					{typeof text === 'string' &&
						<Text textSize={textSize} alignment={alignment}><p>{text}</p></Text>
					}

					{text && typeof text !== 'string' && !text.raw &&
						<Text textSize={textSize} alignment={alignment}>{text}</Text>
					}

					{actions && (
						<ButtonActions buttons={actions} alignment={alignment}>
							{actions.map((action, index) => {
								if (action.__typename === 'ContentfulButton') {
									let actionTheme = 'default'
									if (action.theme === 'primary') {
										actionTheme = themes[theme].buttonTheme || 'default'
									} else if (action.theme === 'secondary') {
										actionTheme = themes[theme].buttonThemeSecondary || 'default'
									}
									return (
										<ActionWrapper key={'button-' + index}>
											<Button
												setTheme={actionTheme}
												to={action.to || action.linkToPage.slug}
												external={action.to}
												target={action.openInNewTab ? '_blank' : ''}
												title={action.label}
												name={action.label}
												shape="pill"
											>
												{action.label}
											</Button>
										</ActionWrapper>
									)
								} else {
									return (
										<ActionWrapper key={'button-' + index}>
											<TextLink
												to={action.to || action.linkToPage.slug}
												external={action.to}
												target={action.openInNewTab ? '_blank' : ''}
												title={action.label}
												name={action.label}
											>
												{action.label}
											</TextLink>
										</ActionWrapper>
									)
								}
							})}
						</ButtonActions>
					)}
					</TextWrapper>
				</TextContainer>
			</div>
		</Wrapper>
	)
}

TextLockup.defaultProps = {
	alignment: 'inherit',
	textSize: 'body',
	entranceDelay: 0,
	transitionIn: true
}

export default TextLockup
