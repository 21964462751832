import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import Video from 'src/components/Video'

import { typography, mq, colors, animations, globals } from 'src/styles'

const RichTextWrapper = styled.div`
	white-space: pre-wrap;
	* {
		white-space: pre-wrap;
	}
	p {
		min-height: 1em;
	}
	> * {
		&:first-child {
		  margin-top: 0;
		}
		&:last-child {
		  margin-bottom: 0;
		}
	}
	a {
		border-bottom: 1px solid ${ colors.lightTextColor };
		&:hover {
			border-color: ${ colors.orange };
		}
	}
`

const InlineMedia = styled.div`
	display: block;
	${ ({ first }) => first ? `
		margin-top: 0;
	` : `
		margin-top: 24px;
	` }
	${ ({ last }) => last ? `
		margin-bottom: 0;
	` : `
		margin-bottom: 24px;
	` }
`

const renderMedia = (file, image) => {
	if (file.contentType.includes('video')) {
		return (
			<InlineMedia as={Video} src={file.url} />
		)
	} else if (image) {
		return (
			<InlineMedia as={GatsbyImage} image={image} alt={file.fileName} />
		)
	} else {
		return false
	}
}

const options = (linkInNewTab, richText) => {
	const textOptions = {
		renderMark: {
			[MARKS.BOLD]: text => <b>{text}</b>,
		},
		renderNode: {
			[INLINES.EMBEDDED_ENTRY]: (node, children) => {
				// find the entry in the entryMap by ID
				const entry = entryMap.get(node.data.target.sys.id);
		
				// render the entries as needed
				if (entry.__typename === "Page") {
					return <a href={`/${entry.slug}`}>{entry.title}</a>;
				}
			},
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<p>{children}</p>
			),
			[BLOCKS.HEADING_1]: (node, children) => (
				<h1>{children}</h1>
			),
			[BLOCKS.HEADING_2]: (node, children) => (
				<h2>{children}</h2>
			),
			[BLOCKS.HEADING_3]: (node, children) => (
				<h3>{children}</h3>
			),
			[BLOCKS.HEADING_4]: (node, children) => (
				<h4>{children}</h4>
			),
			[BLOCKS.HEADING_5]: (node, children) => (
				<h5>{children}</h5>
			),
			[BLOCKS.HEADING_6]: (node, children) => (
				<h6>{children}</h6>
			),
			[BLOCKS.UL_LIST]: (node, children) => (
				<ul>{children}</ul>
			),
			[BLOCKS.OL_LIST]: (node, children) => (
				<ol>{children}</ol>
			),
			[BLOCKS.LIST_ITEM]: (node, children) => (
				<li>{children}</li>
			),
			[BLOCKS.QUOTE]: (node, children) => (
				<blockquote>{children}</blockquote>
			),
			[BLOCKS.HR]: (node, children) => (
				<hr/>
			),
			[BLOCKS.EMBEDDED_ENTRY]: (node, children) => (
				<div>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				const file = node.data.target.file
				const image = node.data.target.gatsbyImageData
	      return (
	        <Fragment>
	          {renderMedia(file, image)}
	        </Fragment>
	      )
	    },
			[INLINES.HYPERLINK]: (node, children) => {
				// console.log('render contentful rich text hyperlink')
				if ( node.data.uri.indexOf("htt") == 0 ) {
					// console.log('outside link')
					return (
						<a href={node.data.uri} target='_blank' rel='noopener noreferrer'>{children}</a>
					)
				} else {
					// console.log('link internal')
					return (
						<a href={node.data.uri}>{children}</a>
					)
				}
			}
		},
	}
	return textOptions
}

const ContentfulRichText = ({ richText, className, linkInNewTab = true }) => {
	if (!richText || !richText.raw) {
		return false
	}

	let rawObj = JSON.parse(richText.raw)

	rawObj.content[0].firstItem = true
	rawObj.content[rawObj.content.length - 1].lastItem = true

	rawObj = JSON.stringify(rawObj)

	// TODO move space from first and last pieces of content
	// let renderer know which is the first item
	// text[0].first = true
	// text[text.length - 1].last = true

	/* Temporarily remove funky logic
	if (text[text.length - 1].content[0].value === '' && text[text.length - 1].content.length === 1) {
		// remove last item if empty
		text.pop()
		// let renderer know which is the last item
		text[richText.content.length - 1].last = true
	} else {
		// let renderer know which is the last item
		text[richText.content.length - 1].last = true
	}
	*/

	return (
		<RichTextWrapper className={className}>
			{renderRichText(richText, options(linkInNewTab, richText))}
		</RichTextWrapper>
	)
}

// ContentfulRichText.defaultProps = {
// 	richText: richText
// }

export default ContentfulRichText
