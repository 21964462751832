import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'
import Slide from 'src/components/Slide'
import Section from 'src/components/Section'
import Slider from 'react-slick'
import ArrowLeft from 'src/assets/images/prev_arrow.svg'
import ArrowRight from 'src/assets/images/next_arrow.svg'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Grid from 'src/components/Grid'

import { typography, mq, colors, util, animations } from 'src/styles'

const Wrapper = styled(Section)`
  ${ util.responsiveStyles('margin-top', 90, 200, 32, 26) }
  ${ util.responsiveStyles('margin-bottom', 90, 200, 32, 26) }
`

const ArrowControl = styled.button`
  appearance: none;
  width: 75px;
  height: 75px;
  padding: 0;
  cursor: pointer;
  position: absolute;
  z-index: 4;
  top: 35%;
  ${ mq.smallAndBelow } {
    width: 25px;
    height: 25px;
    top: -30px;
  }
  ${ ({ position }) =>
    position === 'left'
      ? `
      left: -50px;
      ${ mq.smallAndBelow } {
        left: 0;
        // left: ${ 100 / 14 }vw;
      }
	`
      : `
      right: -50px;
      ${ mq.smallAndBelow } {
        right: 0;
      // right: ${ 100 / 14 }vw;
      }
	` }
  background: transparent;
  color: ${ colors.mainColor };
  border: none;
  outline: none;
  transition: color ${ animations.mediumSpeed } ease-in-out,
    opacity ${ animations.mediumSpeed } ease-in-out;
  ${ ({ hide }) =>
    hide &&
    `
		// opacity: 0;
		// pointer-events: none;
	` }
  &:hover {
  }
  svg {
    width: 75px;
    height: 75px;
    display: block;
    ${ mq.smallAndBelow } {
      width: 25px;
      height: 25px;
    }
    
    * {
      transition: stroke ${ animations.mediumSpeed } ease-in-out;
      // stroke: currentcolor;
      // stroke-width: 1px;
      // stroke-linecap: butt;
      // stroke-linebutt: butt;
      // stroke-linejoin: miter;
      color: ${ colors.mainColor };
    }
    &:hover {
      * {
        // stroke: ${ colors.green };
      }
    }
  }
`

const StyledSlider = styled(Slider)`
  // padding-bottom: 40px;
  .slick-slide {
    // width: 100vw;
    ${ mq.mediumAndBelow } {
      // width: 50vw;
    }
  }
`

const SlideshowDots = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`

const SlideshowDot = styled.li`
  width: 10px;
  height: 10px;
  color: ${ colors.orange };
  background: currentcolor;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 5px;
  border: 1px solid currentcolor;
  transform: scale(0.6);
  transition: transform ${ animations.mediumSpeed } ease-in-out,
    background ${ animations.mediumSpeed } ease-in-out;
  .slick-active & {
    transform: none;
    background: transparent;
  }
`

const SlideshowDescription = styled.div`
  margin-top: 25px;
`

const Carousel = ({ className, animation, slides, description }) => {
  console.log('slides: ', slides)
  const [activeSlide, setActiveSlide] = useState(0)
  const slideFade = animation === 'fade'
  const customSlider = useRef()

  const NextArrow = ({ onClick }) => (
    <ArrowControl
      position="right"
      onClick={onClick}
      aria-label="Go to next image"
    >
      <ArrowRight />
    </ArrowControl>
  )
  const PrevArrow = ({ onClick }) => (
    <ArrowControl
      position="left"
      onClick={onClick}
      aria-label="Go to previous image"
    >
      <ArrowLeft />
    </ArrowControl>
  )

  function goToNextSlide () {
    customSlider.current.slickNext()
  }

  function goToPrevSlide () {
    customSlider.current.slickPrev()
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    centerMode: false,
    swipe: true,
    fade: slideFade,
    accesibility: true,
    // initialSlide: 2,
    speed: 500, // swipe animation speed
    // variableWidth: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
    customPaging: (slider, i) => (
      <SlideshowDot active={slider === i}>
        {i}
        {slider.current}
      </SlideshowDot>
    ),
    responsive: [
      {
        breakpoint: 700,
        settings: {
          dots: false,
          arrows: true,
          variableWidth: false,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
          customPaging: (slider, i) => (
            <SlideshowDot active={slider === i}>
              {i}
              {slider.current}
            </SlideshowDot>
          ),
        },
      },
    ],
  }

  return (
    <Wrapper className={className}>
      <Grid
        small="1 [12] 1"
        medium="1 [12] 1"
        large="1 [12] 1"
        larger="1 [12] 1"
      >
        {description ? (
          <SlideshowDescription>
            <ContentfulRichText richText={description.json} />
          </SlideshowDescription>
        ) : (
          false
        )}
        <StyledSlider {...settings}>
          {slides.map((slide, index) => {
            return (
              <Slide
                key={index}
                title={slide.title}
                image={slide.image}
                description={slide.description}
                videoId={slide.videoId}
                videoType={slide.videoType}
                videoLoop={slide.videoLoop}
                quote={slide.quote}
                author={slide.quote.author}
                linkTarget={slide.linkTarget}
              />
            )
          })}
        </StyledSlider>
        
      </Grid>
    </Wrapper>
  )
}

export default Carousel
