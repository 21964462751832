import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import { GatsbyImage } from 'gatsby-plugin-image'
import Video from 'src/components/Video'
import Grid from 'src/components/Grid'
import Link from 'src/components/Link'

import ReactPlayer from 'react-player/file'

import { colors, typography, mq } from 'src/styles'
import TextLockup from 'src/components/TextLockup'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

const Wrapper = styled(Section)`
  ${ ({ overlayTextColor }) => overlayTextColor === 'dark' ? `
    color: ${ colors.textColor };
  ` : `
    color: ${ colors.bgColor };
  ` }

  ${ ({ pageTitle }) => pageTitle === 'Festival' ? `
    // background-color: chartreuse;
    &:first-of-type {
      grid-column-start: 1;
			grid-column-end: 6;
    }
    &:not(:first-of-type) {
      grid-column-start: 1;
      grid-column-end: 6;
      margin-top: 450px;
    }
    ${ mq.smallAndBelow } {
      &:not(:first-of-type) {
        margin-top: 50vh;
      }
    }

  ` : pageTitle === 'What is Asia?' || 'test-video' ? `  
      grid-column-start: 1;
      grid-column-end: 6;
  ` : `
    
  ` }
`

const WideMediaWrap = styled.div`
  ${ ({ height }) => height !== 'auto' ? `
    position: relative;
    z-index: 2;
    height: ${ height };
  ` : `` }
  ${ ({ hasLink }) => hasLink === true ? `
    margin-bottom: 75px;
  ` : `` }
  > div {
    max-height: 100%;
  }
`

const ContentWrap = styled.div`
  position: relative;
`

const MediaImage = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
  ${ ({ height }) => height !== 'auto' ? `
    height: ${ height };
    > div {
      height: ${ height };
      position: relative;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

const MediaVideo = styled(Video)`
  position: relative;
  z-index: 1;
  ${ ({ height }) => height !== 'auto' ? `
    > div {
      height: ${ height };
      min-height: 40vw;
      position: relative;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  ` : `` }
`

const OverlaySection = styled.div`
  position: absolute;
  // top: 0;
  ${ mq.mediumAndUp } {
    bottom: -25%;
  }

  ${ mq.smallAndBelow } {
    bottom: -50%;
  }
  
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: ${ ({ verticalPlacement }) => {
    if (!verticalPlacement) return 'center'
    if (verticalPlacement === 'top') return 'flex-start'
    if (verticalPlacement === 'bottom') return 'flex-end'
    else return verticalPlacement
  } };
  text-align: ${ ({ overlayTextAlignment }) => overlayTextAlignment || 'left' };
  padding-top: 50px;
  h1, h2, h3, h4, h5, h6, p {
    ${ ({ alignment }) => alignment === 'center' ? `
      margin-left: auto;
      margin-right: auto;
    ` : `` }
  }
  h1, h2, h3 {
    max-width: 20em;
  }
  h4, h4 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 36em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    // font-size: 50px !important;
    // font-family: ${typography.secondaryFont};
    // font-weight: ${typography.bold};
  }
`

function getHorizontalPlacementGridValues ({ fullWidth, horizontalPlacement }) {
  if (!fullWidth) {
    return {
      left: {
        medium: '2 [10] 2',
        large: '2 [8] 4',
        larger: '2 [6] 6'
      },
      center: {
        medium: '2 [10] 2',
        large: '3 [8] 3',
        larger: '4 [6] 4'
      },
      right: {
        medium: '7 [5] 2',
        large: '7 [5] 2',
        larger: '7 [5] 2'
      }
    }[horizontalPlacement]
  } else {
    return {
      left: {
        medium: '1 [9] 4',
        large: '1 [8] 5',
        larger: '1 [6] 7'
      },
      center: {
        medium: '2 [10] 2',
        large: '3 [8] 3',
        larger: '4 [6] 4'
      },
      right: {
        medium: '7 [6] 1',
        large: '7 [6] 1',
        larger: '7 [6] 1'
      }
    }[horizontalPlacement]
  }
}

const WideMedia = ({
  media,
  mediaMedium,
  mediaSmall,
  width,
  theme,
  prevTheme,
  nextTheme,
  height,
  eyebrow,
  text,
  paragraphSize,
  actions,
  overlayPlacement,
  overlayTextAlignment,
  isFirstSection,
  overlayTextColor,
  pageTitle,
  linkUrl
}) => {
  if (!media) {
    return false
  }

  const fullWidth = width === 'fullWidth'
  const getMediaType = type => {
    return type.includes('video') ? 'video' : 'image'
  }

  const heightValues = {
    auto: 'auto',
    fullHeight: '100vh',
    mediumHeight: '70vh',
    shortHeight: '50vh'
  }
  let hasUrl = false
  if (linkUrl.includes('/') ){
    hasUrl = true
  }

  const [verticalPlacement, horizontalPlacement] = (overlayPlacement || 'center center').split(' ')
  const overlayGridSettings = getHorizontalPlacementGridValues({ fullWidth, horizontalPlacement })

  const renderMedia = (mediaItem, size) => {
    if (size === 'small' && !mediaItem) {
      mediaItem = mediaMedium || media
    } else if (size === 'medium' && !mediaItem) {
      mediaItem = media
    }
    if (mediaItem) {
      const contentType = mediaItem.file ? getMediaType(mediaItem.file.contentType) : 'image'
      if (contentType === 'video') {
        return <MediaVideo
          video={mediaItem}
          playing={true}
          loop={true}
          height={heightValues[height]}
          posterImage={mediaItem.posterImage}
          autoplay={true}
        />
      } else {
        return <MediaImage
          image={mediaItem.gatsbyImageData}
          loading={isFirstSection ? 'eager' : 'lazy'}
          height={heightValues[height]}
          alt={mediaItem.file.fileName}
        />
      }
    }
    return false
  }

  return (
    <Wrapper
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      setTheme={theme}
      padded={!fullWidth}
      overlayTextColor={overlayTextColor}
      pageTitle={pageTitle}
    >
      <WideMediaWrap height={heightValues[height]} hasLink={hasUrl}>
        <Grid small={fullWidth ? '[1]' : 'container'} medium={fullWidth ? '[1]' : 'container'} large={fullWidth ? '[1]' : 'container'}>
          <ContentWrap>
            <ResponsiveComponent
              small={renderMedia(mediaSmall, 'small')}
              medium={renderMedia(mediaMedium, 'medium')}
              large={renderMedia(media, 'large')}
            />
            { (eyebrow || text || actions) && (
              <OverlaySection
                padded={!fullWidth}
                overlayTextAlignment={overlayTextAlignment}
                verticalPlacement={verticalPlacement}
              >
                <Section padded>
                  <Grid
                    small='container'
                    medium='container'
                    large='container'
                    {...overlayGridSettings}
                  >
                    <TextLockup
                      eyebrow={eyebrow}
                      text={text}
                      textSize={paragraphSize}
                      actions={actions}
                      stroke={false}
                      wideMedia={true}
                      // theme={'transparent'}
                    />
                  </Grid>
                </Section>
              </OverlaySection>
            )}
          </ContentWrap>
        </Grid>
      </WideMediaWrap>
    </Wrapper>
  )
}

WideMedia.defaultProps = {
  width: 'margins',
  height: 'auto'
}

export default WideMedia
