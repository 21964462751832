import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'

import { colors, typography, animations, mq, util } from 'src/styles'
// const Wrapper = styled(Section)``
const Wrapper = styled.div`
  padding: 24px 16px 36px;
  > div {
    padding: 0 !important;
  }
  border-right: 1px solid ${colors.black};
  border-bottom: 1px solid ${colors.black};
  border-left: 1px solid ${colors.black};
  ${ mq.mediumAndBelow } {
    min-height: 40vh;
  }
  ${ ({ active }) => active && `
    background-color: ${ colors.highlight };
  `}
`

const TabSection = ({ 
  className,
  nextTheme,
  prevTheme,
  title,
  text,
  colorTheme,
  actions,
  active,
  id
}) => {
  if (!text) {
    return false
  }
  let alignment;
  const align = {
    // same as in WideMedia.jsx
    left: {
      medium: 'container',
      large: 'container',
      larger: 'container'
    },
    center: {
      medium: 'container',
      large: 'container',
      larger: 'container'
    },
    right: {
      medium: '7 [6] 1',
      large: '7 [6] 1',
      larger: '7 [5] 2'
    }
  }

  if (!alignment || alignment === null) {
    alignment = 'left'
  }
  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      setTheme={colorTheme}
      nextTheme={nextTheme}
      alignment={alignment}
      active={active}
    >
        <TextLockup
          text={text}
          actions={actions}
          theme='transparent'
          stroke={false}
          tabGroup={true}
        />
    </Wrapper>
  )
}

export default TabSection
