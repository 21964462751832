import React from 'react'
import styled from '@emotion/styled'
import themes from 'src/styles/themes'
import { rgba } from 'polished'
import { typography, mq, colors, util } from 'src/styles'

const ThemeWrapper = styled.div`
	${ ({ 'data-theme': setTheme }) => setTheme && setTheme !== 'bgColor' ? `
		// background-color: ${ themes[setTheme].background };
		color: ${ themes[setTheme].color };
		*::selection {
	    background: ${ rgba(themes[setTheme].hoverColor, 0.9) };
	    color: ${ themes[setTheme].background };
	  }
	  p a {
	  	border-color: ${ rgba(themes[setTheme].color, 0.25) };
	  	&:hover {
	  		border-color: ${ themes[setTheme].hoverColor };
	  	}
	  }
	` : `` }

	// ${ mq.mediumAndUp } {
	// 	width: 50%;
	// 	// &:not(:first-of-type) {
	// 	// 	margin-bottom: 110px;
	// 	// }
	// 	display: inline-block;
	// 	flex-basis: 33.3333%
	// }

`

const ThemeSelector = ({ className, setTheme = 'default', ...rest }) => {
	if (!setTheme || setTheme === null) {
		setTheme = 'default'
	}
	return (
		<ThemeWrapper
			data-theme={setTheme}
			className={className}
			{...rest}
		/>
	)
}

export default ThemeSelector
